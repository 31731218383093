/**
 * @type {UserConfig}
 */
const pluginConfig = {
    current_lang: 'en',
    autoclear_cookies: true, // default: false
    page_scripts: true, // default: false

    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: false,                  // default: false
    // remove_cookie_tables: false             // default: false
    cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0
    //<button type="button" data-cc="c-settings" class="cc-link">Manage Cookies</button>

    onFirstAction: function (user_preferences, cookie) {
        // callback triggered only once
       // const analyticsEnabled = window.CC.allowedCategory('analytics');
       // console.log(`analytics ${analyticsEnabled ? 'enabled' : 'disabled'}`);
    },

    onAccept: function (cookie) {
        // ...
    },

    onChange: function (cookie, changed_preferences) {
        // ...
       
    },
    gui_options: {
        consent_modal: {
            layout: 'bar',               // box/cloud/bar
            position: 'bottom center',     // bottom/middle/top + left/right/center
            transition: 'slide',           // zoom/slide
            swap_buttons: true            // enable to invert buttons
        }
    },
    languages: {
        en: {
            consent_modal: {
                title: 'We Care About Your Privacy',
                layout: 'bar',
                position: 'middle left',
                description:
                    `We use cookies to give visitors and users the best experience. For more
                     information about our personal data processing practices, see our  <a href="https://www.altisource.com/Privacy-Policy" target="_blank" class="cc-link">privacy policy</a>
                     
                     `,
                primary_btn: {
                    text: 'Accept All Cookies',
                    role: 'accept_all', // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Manage Cookies',
                    role: 'settings', // 'settings' or 'accept_necessary'
                },
                close_btn: {
                    text: 'close',
                    role: 'close', // 'settings' or 'accept_necessary'
                },
                
            },
            settings_modal: {
                title: 'Information on Cookies',
                layout: 'bar',
                save_settings_btn: 'Confirm My Choices',
                accept_all_btn: 'Note: Rejecting all optional cookies may have a negative impact on the usability and functionality of the site.',
                reject_all_btn: 'Reject All Optional Cookies',
                close_btn_label: 'Close',
                cookie_table_headers: [
                    { col1: 'Name' },
                    { col2: 'Domain' },
                    { col3: 'Expiration' },
                    { col4: 'Description' },
                ],
                blocks: [
                    {
                        title: '',
                        description:
                        `Our website and applications use essential cookies to work properly. In addition, we would like to set: (i) optional “performance” cookies to collect anonymous site visitation and/or use data; and (ii) optional “marketing” cookies to help us understand which content is most valued by our visitors. By enabling our optional cookies, you can help us to provide a better experience for visitors like yourself. For more information about our personal data processing practices, see our <a href="https://www.altisource.com/Privacy-Policy" target="_blank" class="cc-link">Privacy Policy.</a>
                        <div class="heading"><span class="title-msg">Manage Consent Preferences</span></div>
                        `,
                    },
                    {
                        title: `Essential Cookies <span class="badge">Always Enabled</span>`,
                        description:
                            `These are strictly necessary cookies and we use them to make our website work, for example by allowing users to access secure areas in the website. Essential cookies are always on and cannot be deactivated by the user, some browsers may be configured to block these which may cause parts of the website not to work correctly.`,
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                        },
                        cookie_table: [
                            // list of all expected cookies
                            {
                                col1: 'visid_incap_#',
                                col2: 'homeequityshield.com',
                                col3: '1 year',
                                col4: 'Preserves users states across page requests.'
                            },
                            {
                                col1: 'nlbi_#',
                                col2: 'homeequityshield.com',
                                col3: 'session',
                                col4: 'Used to ensure website security and fraud detection.',
                            },
                            {
                                col1: 'AWSALB',
                                col2: 'homeequityshield.com',
                                col3: '7 days',
                                col4: 'Registers which server-cluster is serving the visitor    .'
                            },
                            {
                                col1: 'AWSALBCORS',
                                col2: 'homeequityshield.com',
                                col3: '7 days',
                                col4: 'Registers which server-cluster is serving the visitor.',
                            },
                            {
                                col1: 'CONSENT',
                                col2: 'youtube.com',
                                col3: '2 year',
                                col4: 'Used to detect if the visitor has accepted the marketing category in the cookie banner'
                            }
                        ],
                    },
                    {
                        title: 'Performance Cookies',
                        description:
                            `These allow us to count visits and traffic sources, and monitor which pages are visited more often. These cookies help us to screen and improve the website's health and performance.`,
                        toggle: {
                            value: 'analytics', // your cookie category
                            enabled: true,
                            readonly: false,
                        },
                        cookie_table: [
                            // list of all expected cookies
                            {
                                col1: '_ga_#',
                                col2: 'homeequityshield.com',
                                col3: '2 years',
                                col4: 'Used by Google Analytics to collect data on the number of times a user has visited the website ',
                                is_regex: true,
                            },
                            {
                                col1: '_gid',
                                col2: 'homeequityshield.com',
                                col3: '1 day',
                                col4: 'Registers a unique ID that is used to generate statistical data on how the visitor uses the website.',
                            },
                            {
                                col1: '_gat',
                                col2: 'homeequityshield.com',
                                col3: '1 day',
                                col4: 'Used by Google Analytics to throttle request rate',
                                is_regex: true,
                            },
                            {
                                col1: '_ga',
                                col2: 'homeequityshield.com',
                                col3: '2 years',
                                col4: 'Used by Google Analytics to collect data on the number of times a user has visited the website ',
                                is_regex: true,
                            }
                        ],
                    },
                    {
                        title: 'Marketing Cookies',
                        description:
                            'These allow us to track users across different websites, create visitor profiles and target our advertising.',
                        toggle: {
                            value: 'Marketing',
                            enabled: true,
                            readonly: false,
                        },
                        cookie_table: [
                            // list of all expected cookies
                            {
                                col1: '___utmvc',
                                col2: 'homeequityshield.com',
                                col3: '1 day',
                                col4: 'This information is used in order to optimize the relevance of advertisement on the website.',

                            },
                            {
                                col1: 'TESTCOOKIESENABLED',
                                col2: 'youtube.com',
                                col3: '1 day',
                                col4: 'Used to track user’s interaction with embedded content.',
                            },
                            {
                                col1: 'nextId',
                                col2: 'youtube.com',
                                col3: 'session',
                                col4: 'Used to track user’s interaction with embedded content.',
                            },
                            {
                                col1: 'requests',
                                col2: 'youtube.com',
                                col3: 'session',
                                col4: 'Used to track user’s interaction with embedded content.',
                            },
                            {
                                col1: 'remote_sid',
                                col2: 'youtube.com',
                                col3: 'session',
                                col4: 'Necessary for the implementation and functionality of YouTube video-content on the website.',
                            },
                            {
                                col1: 'LAST_RESULT_ENTRY_KEY',
                                col2: 'youtube.com',
                                col3: 'session',
                                col4: 'Used to track user’s interaction with embedded content.',
                            },
                            {
                                col1: 'yt-remote-device-id',
                                col2: 'youtube.com',
                                col3: 'session',
                                col4: "Stores the user's video player preferences using embedded YouTube video",
                            },
                            {
                                col1: 'yt-remote-cast-installed',
                                col2: 'youtube.com',
                                col3: 'session',
                                col4: "Stores the user's video player preferences using embedded YouTube video",
                            },
                            {
                                col1: 'yt-remote-cast-available',
                                col2: 'youtube.com',
                                col3: 'session',
                                col4: "Stores the user's video player preferences using embedded YouTube video",
                            },
                            {
                                col1: 'yt-remote-session-app',
                                col2: 'youtube.com',
                                col3: 'session',
                                col4: "Stores the user's video player preferences using embedded YouTube video",
                            },
                            {
                                col1: 'yt-remote-session-name',
                                col2: 'youtube.com',
                                col3: 'session',
                                col4: "Stores the user's video player preferences using embedded YouTube video",
                            },
                            {
                                col1: 'yt-remote-fast-check-period',
                                col2: 'youtube.com',
                                col3: 'session',
                                col4: "Stores the user's video player preferences using embedded YouTube video",
                            },
                            {
                                col1: 'yt-remote-connected-devices',
                                col2: 'youtube.com',
                                col3: 'session',
                                col4: "Stores the user's video player preferences using embedded YouTube video",
                            },
                            {
                                col1: 'YtIdbMeta#databases',
                                col2: 'youtube.com',
                                col3: 'persistent',
                                col4: 'Used to track user’s interaction with embedded content.',
                            },
                            {
                                col1: 'yt.innertube::nextId',
                                col2: 'youtube.com',
                                col3: 'persistent',
                                col4: "Registers a unique ID to keep statistics of what videos from YouTube the user has seen.",
                            },
                            {
                                col1: 'ServiceWorkerLogsDatabase#',
                                col2: 'youtube.com',
                                col3: 'persistent',
                                col4: "Necessary for the implementation and functionality of YouTube video-content on the website.",
                            },
                            {
                                col1: 'ytidb::LAST_RESULT_ENTRY_KEY',
                                col2: 'youtube.com',
                                col3: 'persistent',
                                col4: "Used to track user’s interaction with embedded content.",
                            },
                            {
                                col1: 'YSC',
                                col2: 'youtube.com',
                                col3: 'session',
                                col4: "Registers a unique ID to keep statistics of what videos from YouTube the user has seen.",
                            },
                            {
                                col1: 'VISITOR_INFO1_LIVE',
                                col2: 'youtube.com',
                                col3: 'session',
                                col4: "Tries to estimate the users' bandwidth on pages with integrated YouTube videos.",
                            },

                        ],
                    }
                ],
            },
        },
    },
};

export default pluginConfig;
