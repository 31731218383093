import { useEffect } from 'react';
import pluginConfig from '../resources/CookieConsentConfig';
import '../resources/js/cc';

const CookieConsentComponent = () => {
  
  useEffect(() => {
    console.log("useeffect called");
    if (!document.getElementById('cc--main')) {
      window.CC = window.initCookieConsent();
      window.CC.run(pluginConfig);
      const btnDiv = document.getElementById('c-inr');
      const div = document.createElement('span');
      div.setAttribute('class', 'btnClose');
      div.addEventListener("click", function() {
        document.getElementById("cc_div").style.display = 'none';
      });
      div.innerHTML = 'x'; 
      if (btnDiv !== null) {
         btnDiv.appendChild(div);
      }
    }
  }, []);

  return null;
};

export default CookieConsentComponent;
