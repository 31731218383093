import { useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import $ from "jquery";
import "./App.css";
//import 'vanilla-cookieconsent/dist/App.css';
import Home from "./Home";
import Contacts from "./Contacts";
import Faq from "./Faq";
import Terms from "./Terms";
import Header from "./Header";
import Footer from "./Footer";
import ReactGA from 'react-ga';
import CookieConsentComponent from './components/cookieConsent';


// const TRACKING_ID = "UA-231814259-2"; // OUR_TRACKING_ID
// ReactGA.initialize(TRACKING_ID)

function App() {
  const s3ImageUrl = "https://image-prod.hubzu.com/equity";
  const isShowVideo = true;
  const contactUs = "866.609.4414";

  const location = useLocation();

  useEffect(() => {
    $(".outerButton").pulsate({
      // color: $('.innerButton').css("background-color"), // set the color of the pulse
      color: "#aeaeae", // set the color of the pulse
      reach: 14, // how far the pulse goes in px
      speed: 1000, // how long one pulse takes in ms
      pause: 0, // how long the pause between pulses is in ms
      glow: false, // if the glow should be shown too
      repeat: true, // will repeat forever if true, if given a number will repeat for that many times
      onHover: false, // if true only pulsate if user hovers over the element
    });
    $(".outerButton").addClass("pulse");
  
    $(".playButton").hover(
      function () {
        $(".playArrow").css("transform", "scale(1.2)");
        // $('.outerButton').removeClass('pulse');
      },
      function () {
        $(".playArrow").css("transform", "scale(1)");
        $(".outerButton").addClass("pulse");
      }
    );
  }, [location]);

  return (
    <div className="contentWrapper">
      <Header resUrl={s3ImageUrl}  contactUs={contactUs} />
      <Switch>
        <Route exact path="/faq">
          <Faq resUrl={s3ImageUrl} contactUs={contactUs} isShowVideo={isShowVideo}/>
        </Route>
        <Route exact path="/contacts">
          <Contacts resUrl={s3ImageUrl} contactUs={contactUs} isShowVideo={isShowVideo}/>
        </Route>
        <Route exact path="/terms">
          <Terms resUrl={s3ImageUrl} contactUs={contactUs} isShowVideo={isShowVideo}/>
        </Route>
        <Route exact path="/">
          <Home resUrl={s3ImageUrl} contactUs={contactUs} isShowVideo={isShowVideo}/>
        </Route>
        <Redirect to="/" />
      </Switch>
      <Footer resUrl={s3ImageUrl} contactUs={contactUs} isShowVideo={isShowVideo}/>
      <CookieConsentComponent />
    </div>
    
  );
}

export default App;
