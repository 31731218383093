import { buildQueries } from "@testing-library/react";
import { useState } from "react";
import { Redirect } from "react-router-dom";

function FaqItem(props) {
  const { resUrl, question, answer, key } = props;
  const [isOpened, setIsOpened] = useState(false);


  return (
    <>
      <div className="faq">
        <div className="title" onClick={() => setIsOpened(!isOpened)}>
          <div>{question}</div>
          <div className="faqDrop">
            {isOpened 
            ? (<img src={`${resUrl}/images/faq_min.png`} alt="Frequently asked questions" />)
            : (<img src={`${resUrl}/images/faq_plus.png`} alt="Frequently asked questions" />)}
          </div>
        </div>
        <div dangerouslySetInnerHTML={{ __html: answer }} className={isOpened ? "answer show" : "answer hide"} style={{lineHeight:2}}>
        </div>
      </div>
    </>
  );
}
export default FaqItem;
