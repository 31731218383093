import React, { useState, useEffect, useImperativeHandle, useRef } from "react";
import { render } from "react-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import GAEventTracker from "./GAEventTracker";
import ERROR_MSG from "../resources/constants/errorConstants";
import ReactGA from 'react-ga';
import { Link, useLocation } from "react-router-dom";

const LeadForm = React.forwardRef((props, ref) => {

    const [lenderDetails, setLenderDetails] = useState([]);

    const [statesList, setStatesList] = useState([]);

    const [citiesList, setCitiesList] = useState([]);

    const [zipCodeList, setZipCodeList] = useState([]);

    const { formControl, closeEvent, successInfo } = props;

    const [inputFormControl, setInputFormControl] = useState(formControl);


    useEffect(() => {
        getLenderDetails();
    }, []);

    const getLenderDetails = () => {
        axios.get(`/portal/get-lender-provider-codelist`)
            .then((response) => {
                let responseData = response?.data;
                if (responseData && responseData.status == 'SUCCESS') {
                    setLenderDetails(responseData.data)
                }
            }).catch((error) => {
                console.log(error);
            });
            // states call
         //   axios.get(`/signatureseller/diy-account/getAllStates`)
            axios.get(`/portal/stateLists`)
            .then((response) => {
                let responseData = response?.data;
                if (responseData) {
                    setStatesList(responseData)
                }
                
            }).catch((error) => {
                console.log(error);
            });
            console.log("states-", statesList);
    }

  

    return (
        <>
      
            <Formik
                initialValues={
                    {
                        email: "",
                        phone: "",
                        firstName: "",
                        lastName: "",
                        lenderId: "",
                        comments: "",
                        propertyAddress: "",
                        city: "",
                        stateId: "",
                        zip: ""
                    }
                }
                onSubmit={async (values, { setValues, resetForm }) => {
                    let eventCategory = (formControl && formControl) == 'lead' ? 'GetStarted' : 'Contact us';
                    let eventLabel = (formControl && formControl) == 'lead' ? 'ES_GetStartedSubmitted' : 'ES_ContactUsSubmitted';
                    // GAEventTracker(eventCategory, 'Submitted', eventLabel);
                    ReactGA.event({
                        category: eventCategory,
                        action: `Submitted`,
                        label: eventLabel,
                    });

                    setValues({
                        ...values,
                        serviceerror: '',
                        showloader: true,
                    });
                    let requestData = {
                        "leadSource": (formControl && formControl == 'lead') ? "equity_getstarted" : "equity_contacted",
                        "firstName": values.firstName,
                        "lastName": values.lastName,
                        "email": values.email,
                        "propertyAddress": values.propertyAddress,
                        "city": values.city,
                        "stateId": values.stateId,
                        "zip": values.zip,
                        "phone": values.phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3"),
                        "comments": (formControl && formControl == 'contact') ? values.comments : null,
                        "lenderProviderCode": (formControl && formControl == 'lead') ? values.lenderId : null,
                        "pushToSalesforce": "Y"
                    };
                    axios.post(`/portal/saveLead`, requestData)
                        .then((response) => {
                            let responseData = response?.data;
                            if (responseData && responseData.status == 'SUCCESS') {
                                let successLael = (formControl && formControl) == 'lead' ? 'ES_GetStartedSubmittedSuccess' : 'ES_ContactUsSubmittedSuccess';
                                // GAEventTracker(eventCategory, 'SubmittedSuccess', successLael);
                                ReactGA.event({
                                    category: eventCategory,
                                    action: `SubmittedSuccess`,
                                    label: eventLabel,
                                });

                                setValues({
                                    ...values,
                                    responsestatus: true,
                                    showloader: false
                                });
                                const el = document.querySelector('.success-message');
                                (el?.parentElement ?? el)?.scrollIntoView({ behavior: "smooth", block: "start" });
                            } else if(responseData.errorCode == "MAX_ATTEMPTS_REACHED"){

                                ReactGA.event({
                                    category: eventCategory,
                                    action: `SubmittedFailureDueToMaxAttemts`,
                                    label: eventLabel,
                                });

                                let errorMessage = ERROR_MSG[responseData.errorCode] ? ERROR_MSG[responseData.errorCode] : ERROR_MSG['TECH_ERROR'];
                                setValues({
                                    ...values,
                                    serviceerror: errorMessage,
                                    showloader: false
                                });
                                const el = document.querySelector('.service-error');
                                (el?.parentElement ?? el)?.scrollIntoView({ behavior: "smooth", block: "start" });
                            } else {
                                let errorLael = (formControl && formControl) == 'lead' ? 'ES_GetStartedSubmittedFaliure' : 'ES_ContactUsSubmittedFaliure';
                                // GAEventTracker(eventCategory, 'SubmittedFailure', errorLael);
                                ReactGA.event({
                                    category: eventCategory,
                                    action: `SubmittedFailure`,
                                    label: eventLabel,
                                });

                                let errorMessage = ERROR_MSG[responseData.errorMessage] ? ERROR_MSG[responseData.errorMessage] : ERROR_MSG['TECH_ERROR'];
                                setValues({
                                    ...values,
                                    serviceerror: errorMessage,
                                    showloader: false
                                });
                                const el = document.querySelector('.service-error');
                                (el?.parentElement ?? el)?.scrollIntoView({ behavior: "smooth", block: "start" });
                            }
                        }).catch((error) => {
                            let responseData = error?.data;
                            let errorMessage = ERROR_MSG['TECH_ERROR'];
                            setValues({
                                ...values,
                                serviceerror: errorMessage,
                                showloader: false
                            });
                        });
                    const el = document.querySelector('.service-error');
                    (el?.parentElement ?? el)?.scrollIntoView({ behavior: "smooth", block: "start" });
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string()
                        .email('Please enter a valid Email')
                        .required("Email required"),
                    firstName: Yup.string()
                        .required("First name required")
                        .matches(
                            /^[a-zA-Z0-9 \\'\\-\\&\\.\\_\\,x`'‘’`()]*$/g,
                            "Characters allowed: a-z A-Z 0-9 and - . ' _"
                        ),
                    lastName: Yup.string()
                        .required("Last name required")
                        .matches(
                            /^[a-zA-Z0-9 \\'\\-\\&\\.\\_\\,x`'‘’`()]*$/g,
                            "Characters allowed: a-z A-Z 0-9 and - . ' _"
                        ),
                    phone: Yup.string()
                        .required("Phone Number required")
                        .min(10, "Please enter a valid Phone Number"),
                    propertyAddress: Yup.string()
                        .required("Property address required")
                        .matches(
                            /^[a-zA-Z0-9 \\'\\-\\#\\.\\_\\,/;\\:\\-]*$/g,
                            "Characters allowed: a-z A-Z 0-9 and : # _ - / ; : ' , ."
                        ),
                        stateId: Yup.string()
                        .required("State required"),
                        city: Yup.string()
                        .required("City required"),
                        zip: Yup.string()
                        .required("Zip required"),
                    lenderId: Yup.string()
                        .when(formControl, {
                            is: (props) => {
                                return (inputFormControl && inputFormControl == 'lead');
                            },
                            then: Yup.string()
                                .required("Lender ID required")
                                .matches(
                                    /^[a-z0-9]+$/i,
                                    'Allowed only alphanumeric characters'
                                ),
                        }),
                })}
            >
                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        setErrors,
                        setValues,
                        dirty,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        setFieldError,
                        setFieldTouched,
                        resetForm
                    } = props;
                    
                    const location = useLocation();

                    const handleFocus = (event) => {
                        let name = event.target.name,
                            value = event.target.value;
                        if(name && name == 'phone'){
                            setFieldValue(name, value.replace(/\D/g, ''), false);
                        } else {
                            setFieldValue(name, value, false);
                        }
                           
                        setFieldTouched(name, false, false);
                    }
                    const getCitiesList = (value, name) => {
                      //  axios.post(`/signatureseller/my-seller/citiesListForStateId?stateId=`+value)
                        axios.post(`/portal/lean-regn/cityListForState?state=`+value)
                        .then((response) => {
                            let responseData = response?.data;
                            if (responseData ) {
                                setCitiesList(responseData.data)
                            } 
                            setValues({
                                ...values,
                                [name]: value,
                            });
                        }).catch((error) => {
                            let responseData = error?.data;
                            let errorMessage = ERROR_MSG['TECH_ERROR'];
                            setValues({
                                ...values,
                                serviceerror: errorMessage,
                                showloader: false
                            });
                        }); 
                    }

                    
                    const getZipCode = (state, city, name) => {
                        axios.post("/portal/getZip?stateId="+state+"&city="+city)
                      //  axios.post("signatureseller/my-seller/zipListForStateIdAndCity?stateId="+state+"&city="+city)
                        .then((response) => {
                            let responseData = response?.data;
                            if (responseData ) {
                                setZipCodeList(responseData)
                            } 
                            setValues({
                                ...values,
                                [name]: city,
                            });
                        }).catch((error) => {
                            let responseData = error?.data;
                            let errorMessage = ERROR_MSG['TECH_ERROR'];
                            setValues({
                                ...values,
                                serviceerror: errorMessage,
                                showloader: false
                            });
                        }); 
                    }

                    useImperativeHandle(ref, () => ({
                        handleModalClose() {
                            handleModalClose()
                        }
                    }))

                    const handleModalClose = () => {
                        console.log('close event')
                        resetForm();
                    }

                    useEffect(() => {
                        if (isSubmitting) {
                            const el = document.querySelector('.text-input.error');
                            (el?.parentElement ?? el)?.scrollIntoView({ behavior: "smooth", block: "start" });
                        }
                    }, [{ isSubmitting, errors }]);

                    const termsLinkClick = () => {
                        handleModalClose();
                        GAEventTracker(
                            "TermsnConditions",
                            "Tapped",
                            "ES_TermsNConditonsTapped"
                        );
                        if(location.pathname == '/terms'){
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth"
                            });
                        }
                    };

                    return (
                        <>
                            {!values.responsestatus ? <div>
                                {formControl && formControl == 'lead' && (
                                    <div className="title">
                                        Enter your information below to get started!
                                    </div>
                                )}
                                <div className={`loader ${values.showloader ? "show" : "hide"}`}></div>
                                <div className={`loaderOverlay ${values.showloader ? "show" : "hide"}`}></div>
                                <div className={`service-error ${values.serviceerror ? "show" : "hide"}`}>{values.serviceerror}</div>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="twoCol">
                                            <div className="row-relative">
                                                <input
                                                    name="firstName"
                                                    placeholder="First Name*"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={values.firstName || ""}
                                                    onFocus={handleFocus}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    aria-label="first Name"
                                                    className={
                                                        errors.firstName && touched.firstName
                                                            ? "text-input error"
                                                            : "text-input"
                                                    }

                                                />
                                                {errors.firstName && touched.firstName && (<p className="errorInfo">{errors.firstName}</p>)}
                                            </div>
                                            <div className="row-relative">
                                                <input
                                                    name="lastName"
                                                    placeholder="Last Name*"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={values.lastName || ""}
                                                    onFocus={handleFocus}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    aria-label="last Name"
                                                    className={
                                                        errors.lastName && touched.lastName
                                                            ? "text-input error"
                                                            : "text-input"
                                                    }
                                                />
                                                {errors.lastName && touched.lastName && (<p className="errorInfo">{errors.lastName}</p>)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <input
                                            name="email"
                                            placeholder="Email*"
                                            type="text"
                                            autoComplete="off"
                                            value={values.email || ""}
                                            onFocus={handleFocus}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            aria-label="Email"
                                            className={
                                                errors.email && touched.email
                                                    ? "text-input error"
                                                    : "text-input"
                                            }
                                        />
                                        {errors.email && touched.email && (<p className="errorInfo">{errors.email}</p>)}
                                    </div>
                                    <div className="row">
                                        <input
                                            name="phone"
                                            placeholder="Phone*"
                                            type="text"
                                            autoComplete="off"
                                            value={values.phone || ""}
                                            maxLength="10"
                                            onFocus={handleFocus}
                                            aria-label="phone number"
                                            onChange={e => {
                                                e.preventDefault();
                                                const { value, name } = e.target;
                                                const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;

                                                if (value == '') {
                                                    setFieldValue(name, value, false);
                                                    setFieldTouched(name, true, false);
                                                    setFieldError(name, "Phone Number required");
                                                } else if (!regex.test(value.toString())) {
                                                    setFieldValue(name, value.replace(/\D/g, ""), false);
                                                    setFieldTouched(name, true, false);
                                                    setFieldError(name, "");
                                                } else if(value && value.length < 10){
                                                    setFieldValue(name, value, false);
                                                    setFieldTouched(name, true, false);
                                                    setFieldError(name, "Please enter a valid Phone Number");
                                                } else {
                                                    setFieldValue(name, value, false);
                                                    setFieldTouched(name, true, false);
                                                    setFieldError(name, "");
                                                }
                                            }}
                                            onBlur={e => {
                                                e.preventDefault();
                                                const { value, name } = e.target;
                                                const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                                                if (!value) {
                                                    setFieldValue(name, value, false);
                                                    setFieldTouched(name, true, false);
                                                    setFieldError(name, "Phone Number required");
                                                } else if (value && value.length < 10) {
                                                    setFieldValue(name, value, false);
                                                    setFieldTouched(name, true, false);
                                                    setFieldError(name, "Please enter a valid Phone Number");
                                                } else if (value && value.length == 10) {
                                                    setFieldValue(name, value.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3"));
                                                }
                                            }}
                                            className={
                                                errors.phone && touched.phone
                                                    ? "text-input error"
                                                    : "text-input"
                                            }
                                        />
                                        {errors.phone && touched.phone && (<p className="errorInfo">{errors.phone}</p>)}
                                    </div>
                                    <div className="row">
                                        <input
                                            name="propertyAddress"
                                            placeholder="Address of the property*"
                                            type="text"
                                            autoComplete="off"
                                            value={values.propertyAddress || ""}
                                            onFocus={handleFocus}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            aria-label="Email"
                                            className={
                                                errors.propertyAddress && touched.propertyAddress
                                                    ? "text-input error"
                                                    : "text-input"
                                            }
                                        />
                                        {errors.propertyAddress && touched.propertyAddress && (<p className="errorInfo">{errors.propertyAddress}</p>)}
                                    </div>
                                    <div className="row">
                                        <div className="twoCol">
                                            <div className="row-relative-3">
                                            <select 
                                                name="stateId" 
                                                value={values.stateId || ""}
                                                placeholder="State*"
                                                onFocus={handleFocus}
                                                className={
                                                    errors.stateId && touched.stateId
                                                        ? "text-select error"
                                                        : "text-select"
                                                }
                                                onChange={e => {
                                                    e.preventDefault();
                                                    const { value, name } = e.target;
                                                    setValues({
                                                        ...values,
                                                        [name]: value,
                                                    });
                                                    getCitiesList(value, name);
                                                }}
                                                >
                                                <option disabled={true} value=""> State*</option>
                                                {statesList.map((e, key) => {
                                                    return <option key={key} value={e}>{e}</option>;
                                                })}
                                            </select>
                                                {errors.stateId && touched.stateId && (<p className="errorInfo">{errors.stateId}</p>)}
                                            </div>
                                            <div className="row-relative-3">
                                            <select 
                                                name="city" 
                                                value={values.city || ""}
                                                placeholder="City*"
                                                onFocus={handleFocus}
                                                onChange={e => {
                                                    e.preventDefault();
                                                    const { value, name } = e.target;
                                                    getZipCode(values.stateId, value, name);
                                                }}
                                                className={
                                                    errors.city && touched.city
                                                        ? "text-select error"
                                                        : "text-select"
                                                }
                                                >
                                                <option disabled={true} value=""> City*</option>
                                                {citiesList.map((e, key) => {
                                                    return <option key={key} value={e}>{e}</option>;
                                                })}
                                            </select>
                                                {errors.city && touched.city && (<p className="errorInfo">{errors.city}</p>)}
                                            </div>
                                            <div className="row-relative-3">
                                            <select 
                                                name="zip" 
                                                value={values.zip || ""}
                                                placeholder="Zip*"
                                                onChange={handleChange}
                                                onFocus={handleFocus}
                                                className={
                                                    errors.zip && touched.zip
                                                        ? "text-select error"
                                                        : "text-select"
                                                }
                                                >
                                                <option disabled={true} value=""> Zip*</option>
                                                {/* <option value={zipCode}> {zipCode}</option> */}
                                                {zipCodeList.map((e, key) => {
                                                    return <option key={key} value={e}>{e}</option>;
                                                })}
                                                
                                            </select>
                                                {errors.zip && touched.zip && (<p className="errorInfo">{errors.zip}</p>)}
                                            </div>
                                        </div>
                                    </div>
                                   
                                    {formControl && formControl == 'lead' && (
                                        <div className="row">
                                            <input
                                                autoComplete="off"
                                                name="lenderId"
                                                placeholder="Lender Provided ID Number*"
                                                type="text"
                                                value={values.lenderId || ""}
                                                onFocus={handleFocus}
                                                aria-label="lender Id"
                                                onChange={e => {
                                                    e.preventDefault();
                                                    const { value, name } = e.target;
                                                    let errors = {};
                                                    let touched = {}
                                                    if (!value) {
                                                        setFieldValue(name, value, false);
                                                        setFieldTouched(name, true, false);
                                                        setFieldError(name, "Lender ID required");
                                                    } else if(!(/^[a-z0-9]+$/i).test(value)){
                                                        setFieldValue(name, value, false);
                                                        setFieldTouched(name, true, false);
                                                        setFieldError(name, "Please enter a valid Lender ID");
                                                    } else if (value && lenderDetails.indexOf(value) == -1) {
                                                        setFieldValue(name, value, false);
                                                        setFieldTouched(name, true, false);
                                                        setFieldError(name, "Please enter a valid Lender ID");
                                                    } else {
                                                        setFieldValue(name, value, false);
                                                        setFieldTouched(name, true, false);
                                                        setFieldError(name, "");
                                                    }
                                                }}
                                                onBlur={e => {
                                                    e.preventDefault();
                                                    const { value, name } = e.target;
                                                    let errors = {};
                                                    let touched = {}
                                                    // const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                                                    if (!value) {
                                                        setFieldValue(name, value, false);
                                                        setFieldTouched(name, true, false);
                                                        setFieldError(name, "Lender ID required");
                                                        // setErrors({'lenderId':"Lender ID required"});
                                                    } else if (value && lenderDetails.indexOf(value) == -1) {
                                                        setFieldValue(name, value, false);
                                                        setFieldTouched(name, true, false);
                                                        setFieldError(name, "Please enter a valid Lender ID");
                                                        // setErrors({'lenderId':"Invalid lender ID"});
                                                    } else {
                                                        setFieldValue(name, value, false);
                                                        setFieldTouched(name, true, false);
                                                        setFieldError(name, "");
                                                    }
                                                }}
                                                className={
                                                    errors.lenderId && touched.lenderId
                                                        ? "text-input error"
                                                        : "text-input"
                                                }
                                            />
                                            {errors.lenderId && touched.lenderId && (<p className="errorInfo">{errors.lenderId}</p>)}
                                        </div>)}
                                    {formControl && formControl == 'contact' && (
                                        <div className="row">
                                            <input
                                                name="comments"
                                                placeholder="Enter your questions here.."
                                                type="text"
                                                autoComplete="off"
                                                value={values.comments}
                                                onFocus={handleFocus}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                aria-label="comments"
                                                className={
                                                    errors.comments && touched.comments
                                                        ? "text-input error"
                                                        : "text-input"
                                                }
                                            />
                                            {errors.comments && touched.comments && (<p className="errorInfo">{errors.comments}</p>)}
                                        </div>)}
                                    <div className="row">
                                        <div className="buttonWrapper ">
                                            <input type="submit" className="button submit" value="Submit" disabled={isSubmitting} />
                                        </div>
                                    </div>
                                    {formControl && formControl == 'contact' && (
                                        <div className="disclaimer">
                                           By pressing Submit and providing my contact information, I agree to be contacted according to Home Equity Shield’s{" "}
                                            <Link to="/terms" onClick={termsLinkClick}>
                                            <span className="nowrap">Terms &amp; Conditions</span>
                                            </Link>{" "}
                                            and{" "}
                                            <a
                                            href="https://www.altisource.com/Privacy-Policy"
                                            onClick={GAEventTracker(
                                                "Contact us",
                                                "Tapped",
                                                "ES_PrivacyPolicyTapped"
                                            )}
                                            target="_blank">
                                            Privacy Policy 
                                            </a>.
                                            This consent includes receiving marketing or advertising calls, texts, emails, artificial voice or prerecorded messages, via autodialed or other automated means, from Altisource S.à r.l., Springhouse, LLC, Premium Title Services, Inc., Altisource Online Auction, Inc., and their affiliates. I understand that this consent is not a condition to receiving any services.
                                        </div>
                                    )}
                                </form>
                                {formControl && formControl == 'lead' && (
                                    <div className="row disclaimer">
                                        By pressing Submit and providing my contact information, I agree to be contacted according to Home Equity Shield’s{" "}
                                        <Link to="/terms" className="terms-link" onClick={termsLinkClick}>
                                            Terms &amp; Conditions
                                        </Link>
                                        &nbsp; and{" "}
                                        <a
                                            href="https://www.altisource.com/Privacy-Policy"
                                            onClick={GAEventTracker(
                                                "PrivacyPolicy",
                                                "Tapped",
                                                "ES_PrivacyPolicyTapped"
                                            )}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Privacy Policy
                                        </a>
                                        . This consent includes receiving marketing or advertising calls, texts, emails, artificial voice or prerecorded messages, via autodialed or other automated means, from Altisource S.à r.l., Springhouse, LLC, Premium Title Services, Inc., Altisource Online Auction, Inc., and their affiliates. I understand that this consent is not a condition to receiving any services.
                                    </div>
                                )} </div> : <div className="success-message">
                                <div className="success-container">
                                    <div className="success-logo"></div>
                                    <p>{successInfo.message}</p>
                                </div>
                            </div>}
                        </>
                    );
                }}
            </Formik>
        </>
    )
})


export default LeadForm;