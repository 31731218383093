import React, { useEffect, useState, useRef } from "react";
import LeadForm from "./components/leadForm";
import GAEventTracker from './components/GAEventTracker';
import ReactGA from 'react-ga';
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";

function Footer(props) {
  const { resUrl, contactUs, isShowVideo } = props;

  const closeRef = useRef();
  const location = useLocation();


  let successDetails = {
    message:"Thank you for submitting this form. An Home Equity Shield representative will reach out shortly to discuss how you can start taking advantage of the Home Equity Shield program."
  }

  const closeOverlayClick = (e) => {
    if(location.pathname == '/contacts' && e.target.classList.contains('closeModalBox')){
      e.preventDefault();
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }

    $('.equity-video-player').each(function(i, eleObj) {
      var url = eleObj.getAttribute('src');
      eleObj.setAttribute('src', '');
      eleObj.setAttribute('src', url);
    });

    closeRef.current.handleModalClose()
    ReactGA.event({
      category: "GetStarted",
      action: `Closed`,
      label: "ES_GetStartedClosed",
    });
  }

  return (
    <>
      <div id="footer" className="subSection">
        <div className="wrapper">
          <div className="logo">
            {/* <img src={`${resUrl}/images/logo_b_w.png`} alt="Home Equity Shield" /> */}
            <img src={`./logo-footer.png`} alt="Home Equity Shield" />
            
            <div className="footDis">
              If you are using a screen reader and are having problems using
              this website, please call{" "}
              <span className="nowrap">(855) 339-6325</span> for assistance.
            </div>
            <p>© 2024 Altisource. All Rights Reserved. </p>
            <div className="footerLinks">
              <a
                href="https://www.altisource.com/Privacy-Policy" target="_blank"
                onClick={GAEventTracker(
                  "PrivacyPolicy",
                  "Tapped",
                  "ES_PrivacyPolicyTapped"
                )}
                rel="noreferrer"
              >
                Privacy Policy
              </a>{" "}
              |{" "}
              <Link
                to="/terms"
                onClick={GAEventTracker(
                  "TermsnConditions",
                  "Tapped",
                  "ES_TermsNConditonsTapped"
                )}
              >
                Terms & Conditions
              </Link>
            </div>
          </div>
          <div className="footerContact">
            <div className="contactWrapper">
              <h3>Contact Us</h3>

              <div className="contactOption">
                <div className="footerIcon">
                  <img
                    src={`${resUrl}/images/location_icon.png`}
                    alt="location"
                  />
                </div>
                <div className="optionText">
                    2300 Lakeview Parkway Suite 756 Alpharetta, GA 30009
                </div>
              </div>

              <div className="contactOption">
                <div className="footerIcon">
                  <img src={`${resUrl}/images/phone.png`} alt="phone number" />
                </div>
                <div className="optionText">
                  <a href={`tel:${contactUs}`}>{contactUs}</a>
                </div>
              </div>

              <div className="contactOption">
                <div className="footerIcon">
                  <img src={`${resUrl}/images/mail.png`} alt="email" />
                </div>
                <div className="optionText">
                  <a href="mailto:info@homeequityshield.com">
                    info@homeequityshield.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footerLine"></div>
      </div>

      <div className="overlay"></div>

      {isShowVideo && (<div className="overlayInnerWrapper video">
        <div className="overlayInner">
          <iframe className="equity-video-player"
            src="https://www.youtube.com/embed/x_9TBuNTHJE" 
            title="YouTube video player"
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen>
          </iframe>
        </div>
      </div>)}
      
      <div className="overlayInnerWrapper formBlock">
        <div className="formScrollWrapper">
          <div className="overlayInner formWrapper">
            <div className="left">
              <h2>Welcome to Home Equity Shield!</h2>
              <p>
                If you have any questions before you get started, feel free to
                reach out to our customer service center via chat using the option
                at the bottom of this page. You can also fill out our <Link to="/contacts" className="white-link closeModalBox" onClick={closeOverlayClick}>contact us</Link> form or call us at <span className="nowrap">866-609-4414.</span>
              </p>
            </div>
            <div className="right">
              <LeadForm formControl="lead" ref={closeRef} successInfo={successDetails}></LeadForm>
            </div>
          </div>
        </div>
      </div>

      <div className="closeOverlay">
        <img
          src={`${resUrl}/svg/close.svg`}
          alt="close"
          onClick={closeOverlayClick}
        />
      </div>
    </>
  );
}
export default Footer;