export const faqs = [
  {	
    id:1,
    question:" What does it mean if I am in Default?	",
    answer:" Default is the failure of the borrower to honor the terms of the loan agreement. While lenders consider any late monthly payment as delinquent, lenders usually view borrowers who are delinquent 120 days or more as in “default.” ",
   },
   {	
    id:2,
    question:"	What is a foreclosure?	",
    answer:"	Foreclosure is the legal process by which a lender attempts to recover the amount owed on a defaulted loan by taking ownership of the mortgaged property and selling it. Typically, a foreclosure is triggered when a borrower misses a specific number of monthly payments.	",
  },
  {	
    id:3,
    question:"	What triggers a foreclosure?	",
    answer:"	Typically, a lender will start foreclosure proceedings when a homeowner is more than 120 days past due on their mortgage.	",
},
{	
  id:4,
  question:"	What’s the first step of the foreclosure process?	",
  answer:"	A foreclosure officially begins when a Notice of Default and Election to Sell is recorded with the county recorder. You will receive notice from your lender that the foreclosure process has begun.	",
},
{	
  id:5,
  question:"	How long does the foreclosure process take?	",
  answer:"	The amount of time required for completion of foreclosure varies significantly depending on the laws that apply where the process takes place. No matter how long it may take, the end result—loss of home and major damage to your credit—means it's a process you'll want to avoid if at all possible.	",
},
{	
  id:6,
  question:"	What is a leaseback sale?	",
  answer:"	If you need time to get your finances in order and find a new home, you could sell your property to someone who will lease the home to you by executing a purchase and sale agreement with a leaseback agreement. Leaseback agreements can sometimes provide you the option to buy back the property once certain conditions have been met. However, this option does have risks, as the investor can borrow against your property or even sell your home while you are leasing it. It is recommended that you seek the advice of legal counsel if you are interested in selling your home using a leaseback agreement.	",
},
{	
  id:7,
  question:"	How do I determine my home equity?	",
  answer:"	Your home equity is determined by subtracting the amount you owe on all loans secured by your property from the final sale price. This includes your primary mortgage as well as any home equity loans, liens, or judgments recorded against the property. From this amount you also need to subtract your brokers’ commission, closing costs, and other fees associated with the sale of your home. The final amount that is left over after all unpaid loan amounts and other fees and expenses have been paid is the equity you have in your home.	",
},
{	
  id:8,
  question:"	How do I determine the value of my property?	",
  answer:"	A broker price opinion  (BPO),  appraisal, or automated valuation model (AVM) reports are ways to estimate the value of a property.  A broker price opinion (BPO) or appraisal are determined by a real estate broker or other qualified individual or firm after inspecting the interior of your property and comparing it to other properties in the area that are listed for sale or have recently sold. An automated valuation model (AVM) report is a software-based tool that helps estimate the value of your property based on a set of different parameters. Depending on your lender, and the tool selected to determine the value of your property to participate in the Home Equity Shield program, you may need to allow a broker or appraiser to enter your home, review its conditions and amenities, and take photos of the property. 	",
},
{	
  id:9,
  question:"	What are my options if I cannot work out a solution with my lender/servicer?	",
  answer:" If you have already spoken with your lender/servicer about options they are able to provide you to get back on track with your mortgage payments but none of them work for you, then you can always try selling your property to pay off the balance of your loan and avoid foreclosure. In some cases, you may be able to sell your property, pay off your loan and still have cash left over from the sale. Home Equity Shield gives you the option to sell your home while potentially pausing late fees and foreclosure proceedings if your lender or servicer agrees to participate in that part of the Home Equity Shield program. If you would like to take advantage of the Home Equity Shield program, <a class = 'getStarted' href='/'>click here</a> to register with the unique ID your lender has provided you. If you do not have this unique ID, please contact your lender to see if they participate in the Home Equity Shield program.",
},
{	
  id:10,
  question:"	What choices do I have when selling my property to avoid foreclosure?	",
  answer:"	There are several different methods to sell your home to pay off your loan and potentially walk away with cash. You can: 1) participate in the Home Equity Shield program and market your home on Hubzu in an open and competitive time-limited bidding process to maximize marketing exposure and make interested buyers compete for your property; 2) hire a real estate agent and list your home for sale via traditional means; or 3) try any other marketing and sales technique such as selling For Sale by Owner (FSBO).	",
},
{	
  id:11,
  question:"	I have decided to sell my home with the help of the Home Equity Shield program. What are the next steps?	",
  answer:"	Start by registering for the program by <a class = 'getStarted' href='/'>clicking here.</a> You will need to enter the unique ID provided to you by your lender. Once you submit your registration, an Home Equity Shield specialist will reach out to you to discuss the program. If you decide to participate in the Home Equity Shield program, your specialist will send you an agreement that you must sign. Once the agreement is fully executed, and depending on your lender, your specialist will order an interior Broker Price Opinion (BPO), appraisal or an automated valuation model (AVM) report, and may also order a title search. You may need to provide the agent doing the BPO (or appraiser completing an appraisal) access to your property. If your lender only requires an AVM, you will not need to grant access to your property. If the BPO, appraisal or AVM indicates that there may be equity in your home and the title search shows that there are no problems with the title to your property, your Home Equity Shield specialist will review this information with you and walk you through the options to sell your property within the Home Equity Shield program. For participating lenders that agree to pause the foreclosure process and associated fees, the pause can be up to 120 days after the agreement has been fully executed provided that you continue to market your property for sale. You must also provide your lender evidence that you are marketing your property for sale.",
},
{	
  id:12,
  question:"	What evidence can I provide to prove I'm marketing my property for sale?",
  answer:" If your lender participates in this part of the program, you must provide evidence that you are marketing your property for sale and continue to do so during the pause period. The following can be provided as evidence that you are marketing your property for sale:<ul class = 'evidence' style='list-style-type:disc'><li>A signed listing agreement between you and your real estate agent</li> <li>Screenshots of your property listing on any of the online home listing sites</li> <li>A screenshot of your home on a For Sale By Owner (FSBO) listing site </li> <li> If you are using Huzbu's Signature Seller program, you don't have to provide evidence since your Home Equity Shield specialist will be helping you with this process.</li></ul>",
},
{	
  id:13,
  question:" What happens if my lender does not agree to pause the foreclosure process while on the Home Equity Shield program?",
  answer:" Some lenders may not participate in this part of the Home Equity Shield program. If that is the case with your lender, it is important that you remain in constant contact with your lender and let them know of your efforts to sell your property. Keep in mind that one of the biggest benefits of the Home Equity Shield program is giving you access to additional selling options, like Hubzu’s Signature Seller program. This unique program opens you up to a user base of over 2 million buyers and investors looking to buy properties like yours. Home Equity Shield can also help you negotiate a possible leaseback option, which can keep in the home while you figure out your next move.",
},
{	
  id:14,
  question:"	What is the advantage of using online auction marketing to sell my home?	",
  answer:"	An online auction is a great way to maximize the marketing exposure of your property to the broadest buyer base and make those buyers compete against each other in a fair and open sale. Hubzu will partner with your local listing agent to add even more local, regional, and national multichannel marketing to make sure all interested buyers in the market are aware and engaged in the sale of your property. Auction marketing is a quick way for buyers to compete against each other and the time limited nature of the online auction forces buyers to get off the fence and place their bid to avoid losing out on the opportunity to purchase.	",
},
{	
  id:15,
  question:"	Can I choose my own listing agent?	",
  answer:"	Yes, you are in complete control of which listing agent you want to work with along with the commission structure you negotiate with that listing agent.	",
},
{	
  id:16,
  question:"	When selling via auction, do I still use a listing agent, and will my property still be marketed in the MLS?	",
  answer:"	Yes, you will still work with a listing agent who will act in your best interest to market and sell your home. Hubzu partners with your listing agent to expand the marketing reach of your property and engage buyers in an open and competitive auction.	",
},
{	
  id:17,
  question:"	How should I determine the listing price for my property?	",
  answer:"	Once you enroll in Home Equity Shield, we will work with you and your servicer to value your property at no cost to you. This valuation, once complete, will be shared with you and your listing agent, if you have one selected. You and your listing agent, if applicable, will then work together to determine the best list price based upon the estimated value of your property, which also considers your local market and your sales strategy.	",
},
{	
  id:18,
  question:"	How do I determine my starting bid and reserve price when selling my home through auction?	",
  answer:"	If you choose to sell your property via auction marketing, then there will also be a starting bid and reserve price associated with the auction. Hubzu can help you set your starting bid price, which is the initial bid price at which the auction opens, and which is generally a bit lower than your reserve price. The reserve price is the lowest price you are willing to accept for your property and you are not obligated to sell the property if the auction doesn’t produce a high bid at or above your reserve price. The reserve price is confidential to you, the seller, and will not be known by any bidders. The reserve price is set by you with the help of your listing agent and is generally based upon the value of the property and should be at least as much as is owed on the property so you don’t end up in a short-sale situation, which would then need to be negotiated with your lender/servicer.	",
},
{	
 id:19,
 question:"	Can I stay in the property after I sell it?	",
 answer:"	You have the option to market your property to potential buyers with a mandatory leaseback to you after you sell the property. This way you can still live in the home after you sell it for the duration of the lease. This is an attractive option to some sellers who enjoy living in their home but need to sell it because they cannot afford the mortgage. Keep in mind that you will still have rental payment obligations to the new owner of the property and the lease payment structure will be set before you begin marketing your property so potential buyers know what they will be purchasing and for how much they will be renting the property back to you. You will also be required to comply with other terms of the lease, which could include making advanced payments of the first and last months’ rent.	",
},
{	
  id:20,
  question:"	What forms are required to sell my property?	",
  answer:"	There are several different forms required to sell your home but your listing agent and auction company, if applicable, can help you with all of them. In general, you will have a listing agreement between you and your listing agent that outlines the agent’s duties and their fee structure, among other things. You will likely need to complete a seller’s property condition disclosure (listing agent provided) that will be provided to potential buyers and their agents. There will be an auction services agreement, in case you choose to auction your property. There will be a purchase and sale agreement between you and the buyer once there is an offer/bid that you would like to accept. Finally, there will be closing documents required to effectuate the sale and conveyance of your property. 	",
},
{	
 id:21,
 question:"	What happens after I have found a buyer for my house?	",
 answer:"	Once you have found a buyer for your property, you will enter into a formal purchase and sale agreement, which your listing agent or the buyer’s agent will facilitate. This agreement will detail the terms of the offer and the contractual requirements of the parties leading up to and including the closing/conveyance of the property. The buyer will often select their own preferred title/closing company and that company, along with your listing agent, will coordinate the closing and make sure the buyer and seller are working towards a successful, on-time closing.",
},
{	
 id:22,
 question:"What are the typical fees I should be aware of when selling my property?	",
 answer:"<p>The typical fees you should be aware of consist of marketing and closing fees. If engaging a real estate listing agent, you will likely be required to pay a negotiated commission which will generally be a percentage of the sales price and paid at closing out of the seller’s proceeds. Although there is no standard broker commission, you can expect to pay around 6% of the final sale price. However, you are able negotiate this fee.</p> <p>If you choose to utilize Hubzu to broaden the marketing efforts and competition among buyers, the buyer may be charged, not you the seller, a buyer's premium, which is typically 5% of the accepted bid price. The buyer's premium is paid in addition to the accepted bid price, and thus, you as the seller will receive the full bid price should you choose to accept it. </p> <p>If you participate in the Home Equity Shield program, your lender will cover the costs of the Broker Price Opinion or appraisal (BPO) and/or the title search. The BPO costs around $150  and the title search costs around $115. If your lender decides to proceed with an automated valuation model (AVM) report, such AVM will be performed free of cost. </p> <p>Other fees may be associated with the closing process. Closing fees vary a lot by location, with some fees being more common for the seller to pay and some being more common for the buyer to pay. In most cases you will see some combination of title fees, taxes, recording fees, documentation fees, attorney fees (in some states), and prorated taxes and HOA fees. You should connect with your listing agent and closing company to obtain a good estimate of these fees. </p>",
},
];
