import React, { useEffect, useState } from "react";
import LeadForm from "./components/leadForm";
import GAEventTracker from './components/GAEventTracker';

function Contacts(props) {
  const { resUrl, contactUs, isShowVideo} = props;

  let successDetails = {
    message:"Thank you for submitting this form. An Home Equity Shield representative will reach out shortly to discuss how you can start taking advantage of the Home Equity Shield program."
  }

  return (
    <>
      <div id="hero" className="faqPage getsParallax parallax">
        <div className="wrapper">
          <div className="intro">
            <div className="blueSecHeader">Contact Us</div>
            <div className="ctas">
              <div className="sub_headline">
                <h1>We’re Here to Help You </h1>
              </div>
              <div className="heroButtons">
              {isShowVideo && (<div className="playButton">
                  <div className="innerButton"></div>
                  <div className="outerButton"></div>
                  <div
                    className="playArrow"
                    onClick={GAEventTracker(
                      "Header",
                      "Tapped",
                      "ES_VideoPlayerTapped"
                    )}
                  >
                    <img src={`${resUrl}/images/play_arrow.png`} alt="Play Arrow" />
                  </div>
                </div>)}
              </div>
            </div>

            <p>
              Fill out the form below to contact our customer service center.
              You also have the option to chat or call us at {contactUs}
            </p>
          </div>
          <div className="heroImg">
            <img src={`./contact_us.png`} alt="contact us" />
          </div>
        </div>
      </div>

      <div id="contactSection" className="getsParallax parallax">
        <div className="wrapper">
          <div className="contact_left">
            <h2>Contact Us</h2>
            <div className="contact_phone">
              <div className="image">
                <img src={`${resUrl}/images/phone.png`} alt="phone" />
              </div>
              <div>
                <a href={`tel:${contactUs}`}>{contactUs}</a>
              </div>
            </div>
            <div className="contact_email">
              <div className="image">
                <img src={`${resUrl}/images/mail.png`} alt="emaii" />
              </div>
              <div>
                <a href="mailto:info@homeequityshield.com">info@homeequityshield.com</a>
              </div>
            </div>
            <div>
              You may also reach out to us via chat:<br/>Monday to Friday 7:00am to
              7:00pm EST
            </div>
            <div>
              For any questions regarding additional options besides Home Equity
              Shield, please reach out directly to your lender. Home Equity Shield
              representatives can only provide information about the Home Equity
              Shield program.
            </div>
          </div>
          <div className="contact_right">
            <LeadForm formControl="contact" successInfo={successDetails}></LeadForm>
          </div>
        </div>
      </div>
      <div id="gettingStarted" className="subSection">
        <div className="wrapper">
          <div className="content">
            <h2>Getting Started Is Easy</h2>
            <p>
                Click Get Started today and let Home Equity Shield walk you through the progrm.{" "}
            </p>
          </div>
          <div className="buttonWrapper ">
            <a href="#" className="button getStarted">
              Get Started{" "}
              <img
                src={`${resUrl}/images/chevrons.png`}
                alt="get started"
                onClick={GAEventTracker(
                  "Contact us",
                  "Tapped",
                  "ES_FooterGetStartedTapped"
                )}
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
export default Contacts;
