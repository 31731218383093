import GAEventTracker from "./components/GAEventTracker";

function Terms(props) {
  const { resUrl, isShowVideo} = props;

  return (
    <>
      <div id="hero" className="faqPage termsPage getsParallax parallax">
        <div className="wrapper">
          <div className="intro">
            <div className="ctas">
              <div className="sub_headline">
                <h1>Terms and Conditions</h1>
              </div>
              <div className="heroButtons">
                {isShowVideo && (<div className="playButton">
                  <div className="innerButton"></div>
                  <div className="outerButton "></div>
                  <div
                    className="playArrow"
                    onClick={GAEventTracker(
                      "Header",
                      "Tapped",
                      "ES_VideoPlayerTapped"
                    )}
                  >
                    <img
                      src={`${resUrl}/images/play_arrow.png`}
                      alt="video player"
                    />
                  </div>
                </div>)}
              </div>
            </div>
          </div>
          <div className="heroImg">
          </div>
        </div>
      </div>

      <div id="faqSection" className="termsPageConditions getsParallax parallax">
        <div className="wrapper">
            <div className="effective-txt"><b>Effective: June 21<sup>st</sup>, 2022</b></div>
            <p>
                These terms and conditions (the "Agreement") are a legal agreement between you and 
                Altisource S.à r.l. ("Altisource", "us", "our" or "we"). Please note that by accessing the 
                Home Equity Shield website (<a href="/">www.homeequityshield.com</a>) and receiving the related services 
                (collectively, "Home Equity Shield"), you hereby agree to be bound by the terms of this Agreement. 
            </p>
            <p>
                Please note that this Agreement includes a class action waiver, a disclaimer of warranties, 
                a disclaimer of liability, as well as a release and indemnification by you. Your consent to be 
                contacted using an automatic telephone dialing system, and how to revoke such consent, 
                is detailed below in Section 9.
            </p>
            <p>
                We may change the terms of this Agreement from time to time. We will notify you of any such 
                changes via e-mail, to the extent provided, or by posting the changes via a link to the updated 
                Agreement on the Home Equity Shield website. If you object to any such changes, your sole recourse 
                will be to stop participating in the Home Equity Shield program. Continued participation in the 
                Home Equity Shield program following any such changes will indicate your acknowledgement of such 
                changes and agreement to be bound by the terms and conditions of such changes, without 
                limitation or qualification. You hereby agree that you are solely responsible for keeping 
                apprised of any updates to the Agreement that are posted on the Home Equity Shield website.  
            </p>
            <p>
                In addition to accepting and complying with the terms of this Agreement, any Borrower 
                (as defined below) who wants to participate in the Home Equity Shield program must execute 
                an Home Equity Shield Agreement (as defined below) setting forth the Borrower's obligations, 
                among other things, to: (a) allow a title search and an interior broker's price opinion or 
                other appraisal ("BPO") to be performed on their property and (b) market their property 
                for sale in accordance with the terms of the Home Equity Shield Agreement. 
                The Home Equity Shield Agreement contains the terms and conditions governing the Borrower's 
                participation in the Home Equity Shield program. In case of any conflict between the provisions 
                in this Agreement and the Home Equity Shield Agreement, the Home Equity Shield Agreement shall control 
                with respect to the parties bound thereto. 
            </p>
            <h4>DEFINED TERMS</h4>
            <p>As used in this Agreement or on Home Equity Shield website, the below terms shall have the following meanings:</p>
            <p>"Altisource" has the meaning assigned to such term in the opening paragraph to this Agreement.</p>
            <p>
                "Borrower" means a person who has a loan agreement with a Lender (as defined below) and is 
                authorized by the Lender to participate in the Home Equity Shield program. The Lender will provide 
                the Borrower an ID Code (as defined below) that allows the Borrower to participate in the 
                Home Equity Shield program and the Borrower must provide the ID Code to Altisource when signing up 
                for the Home Equity Shield program.
            </p>
            <p>
                "Home Equity Shield Agreement" means the agreement executed by a Borrower, Lender, and Altisource 
                that sets forth the terms and conditions governing the Borrower's 
                participation in the Home Equity Shield program. Also means, any auction and marketing agreement, 
                purchase agreement and other agreement that the Borrower executes during the participation 
                in the Home Equity Shield program
            </p>
            <p>
                "ID Code" means the confidential identification code that a Lender will provide to a 
                Borrower authorizing the Borrower to participate in the Home Equity Shield program and that the 
                Borrower must provide to Altisource when signing up for the Home Equity Shield program.
            </p>
            <p>
                "Lender" means a person or entity that: (a) made a loan to a Borrower subject to a loan 
                agreement and/or is responsible for servicing the Borrower’s loan, and (b) has authorized 
                the Borrower to participate in the Home Equity Shield program.  
            </p>
            <h6>1. Services; Fees.</h6>
            <p>
                (a) <span className="underline-txt">Grant of Limited Access</span>. Subject to the terms of this Agreement, we hereby grant you a 
                limited, revocable, non-exclusive, non-transferable and non-sublicensable right to access, 
                view and use the Home Equity Shield website solely for your personal use.
            </p>
            <p>
                (b) <span className="underline-txt">Proprietary Property</span>. Home Equity Shield, including all material and information contained 
                therein, and the selection, arrangement and composition of such information, is proprietary 
                property of Altisource, its affiliates, suppliers, and licensors and are protected by 
                United States and international intellectual property laws, including trademark and 
                copyright laws. You agree not to remove, alter or obscure any copyright, trademark or 
                proprietary rights notice incorporated into or posted on the Home Equity Shield website. 
                Except for the limited access rights explicitly granted to you under this Agreement, 
                no other rights (including any license) are granted to you, whether by implication, 
                estoppel or otherwise and all rights are explicitly reserved.
            </p>
            <p>
                (c) <span className="underline-txt">Use and Storage</span>. You acknowledge and agree that we may establish general practices and 
                limits concerning the use of the Home Equity Shield website and participation in the Home Equity 
                Shield program, including, without limitation, the maximum number of days that information, 
                data, account history or other uploaded content will be retained by us and the maximum 
                number of times (and the maximum duration for which) you may access the Home Equity Shield 
                website in a given period of time.
            </p>
            <h6>2. Eligibility and Your Responsibilities.</h6>
            <p>
                (a) <span className="underline-txt">Who Can Use Home Equity Shield</span>. Home Equity Shield will only be available for Borrowers who 
                receive an ID Code from their Lender authorizing them to participate in the Home Equity Shield 
                program. Only individuals who can form legally binding contracts under applicable law can 
                use the Home Equity Shield website. Without limiting the foregoing, the Home Equity Shield 
                program is not available to minors or to temporarily or indefinitely suspended users. 
                If you do not qualify, please do not use the Home Equity Shield website. Further, your ID Code 
                and account information may not be transferred or sold to another party. 
                If you are registering as a business entity, you represent that you have the authority to 
                bind the entity to this Agreement. If you do not have such authority, you will be held 
                liable for all actions taken on your account.
            </p> 
            <p>
                (b) <span className="underline-txt">Creation of an Account; Accuracy of Information</span>. As part of the registration 
                process you will be asked to provide certain personal information to us. For more information 
                about how we handle your personal information, please see our Privacy Policy 
                here (<a href="https://www.altisource.com/Privacy-Policy" target="_blank">https://www.altisource.com/Privacy-Policy</a>), which is incorporated herein by reference. 
                In providing us with information during the registration process, you agree to provide 
                true, accurate, current and complete information and to maintain and update such 
                information as needed. You are responsible for: (a) obtaining and maintaining all telephone, 
                computer hardware and other equipment needed to access the Home Equity Shield website and 
                participate in the Home Equity Shield program, and (b) paying all charges related thereto. 
                The Home Equity Shield website is merely a passive conduit for online distribution and 
                publication of information.
            </p>
            <p>
                (c) <span className="underline-txt">Identity Verification and Use</span>. We use many techniques to identify our users when they 
                register. However, because user authentication on the internet is difficult, we cannot and 
                do not confirm each user's purported identity. We encourage you to report any instances of 
                fraud or other circumstances that call into question the purported identity of any 
                participant in the Home Equity Shield program. You are solely responsible for any information 
                you provide to us and for maintaining the confidentiality of that information, and you are 
                solely responsible for all activities that occur under your name or on your account. 
                You agree to immediately notify us of any unauthorized use of your confidential information 
                or any other breach of security related to the Home Equity Shield program.
            </p> 
            <p>
                (d) <span className="underline-txt">Restricted Activities; Submission of Harmful Material</span>. You agree not to post, submit or 
                link to any material that (i) is deceptive, false, inaccurate, misleading, fraudulent, 
                threatening, harassing, libelous, defamatory, obscene or otherwise objectionable as 
                determined by us or under applicable law; (ii) directly or indirectly directs users to 
                another online or offline location that provides products or services similar to the 
                Home Equity Shield program; (iii) involves unsolicited or unauthorized advertising, promotional 
                materials, junk mail, spam, chain letters, pyramid schemes, or any other form of 
                solicitation; (iv) infringes the intellectual property rights, privacy rights or other 
                legal rights of any individual or entity; (v) includes any code, files, scripts, agents, 
                programs or other computer programming routines intended to do harm or that may damage, 
                detrimentally interfere with, surreptitiously intercept or expropriate any system, 
                data or personal information, including, for example, viruses, worms, time bombs and 
                Trojan horses; (vi) creates liability for us or causes us to lose (in whole or in part) 
                the services of our ISPs or other suppliers; and (vii) interferes with, 
                disrupts or otherwise adversely impacts the use of the Home Equity Shield website by any other 
                individual or entity.
            </p>
            <p>
                (e) <span className="underline-txt">Reverse Engineering</span>. You may not reverse engineer, decompile or disassemble the 
                Home Equity Shield website. You may not use automated web queries 
                (including, without limitation, screen and database scraping, spiders, robots, crawlers 
                and any other automated activity in connection with the Home Equity Shield website). 
                General purpose Internet search engines and non-commercial public archives that use tools 
                to gather information for the sole purpose of displaying hyperlinks to the services are 
                granted a limited exception from the foregoing exclusion, provided that they do so from a 
                stable IP address or range of IP addresses using an easily-identifiable agent. 
                You may not frame any part of the Home Equity Shield website. You may not use, or attempt to use, 
                the Home Equity Shield website through any means not explicitly and intentionally 
                made available, provided or intended with respect to Home Equity Shield. 
                You may not harvest information about users of Home Equity Shield for any purpose. 
                You may not use Home Equity Shield services in any manner that could damage, disable, 
                overburden, or impair the Home Equity Shield program or that harms Altisource, our service 
                providers, suppliers or any other person.
            </p>
            <p>
                (f) <span className="underline-txt">Bulk Downloads</span>. The content offered as part of the Home Equity Shield program is not 
                intended for bulk downloads, and thus, you are expressly prohibited from downloading such 
                content in bulk. For purposes of further clarification, bulk downloads shall include, 
                without limitation, any ten retrieval related actions per minute from an individual 
                IP address or otherwise associated with one user of the website. Unreasonable and/or 
                excessive use, as determined in our sole discretion, may result in access being denied 
                temporarily or permanently for a given user or IP address.
            </p>
            <p>
                (g) <span className="underline-txt">Access and Interference</span>. You agree that you will not use any electronic device, 
                software or process to monitor or copy our web pages or the content contained herein 
                without our prior expressed written permission. You agree that you will not use any 
                electronic device, software or process to interfere with or attempt to interfere with the 
                proper functioning of the Home Equity Shield website or any activities conducted in connection 
                with the Home Equity Shield program. You agree that you will not take any action that imposes 
                an unreasonable or disproportionately large load on our infrastructure. You agree that you 
                will not copy, reproduce, alter, modify, create derivative works, or publicly display any 
                content from the Home Equity Shield website for public or commercial purposes, including the 
                text, images, audio, and video without our prior expressed written permission.
            </p> 
            <p>
                (h) <span className="underline-txt">Termination</span>. Without limiting any of our other remedies, we may immediately issue a 
                warning, temporarily suspend, indefinitely suspend or terminate your account and prohibit 
                you from using the Home Equity Shield website or participating in the Home Equity Shield program 
                if: (a) you breach any terms of this Agreement; (b) we are unable to verify or 
                authenticate any information you provide to us; or (c) we believe that your actions 
                may cause financial loss or legal liability for you, our users or us.
            </p> 
            <h6>3. Privacy.</h6>
            <p>
                Please see your Privacy Rights (<a href="https://www.altisource.com/Privacy-Policy" target="_blank">https://www.altisource.com/Privacy-Policy</a>), 
                which are incorporated herein by reference.
            </p>
            <h6>4. Indemnity.</h6>
            <p>
                You agree to indemnify, defend and hold Altisource and its parents, subsidiaries, 
                affiliates, officers, directors, agents, attorneys, insurers, employees, and 
                representatives harmless from and against any lawsuit, claim, or demand, including 
                without limitation, a claim for attorneys’ fees, made by any third party relating to or 
                arising out of: (a) your breach of any terms of this Agreement or any of the documents 
                incorporated by reference herein, (b) your acts or omissions in connection with your 
                use of the Home Equity Shield website or participation in the Home Equity Shield program, (c) 
                your negligence, gross negligence, willful misconduct, (d) your violation of any law, 
                rule or regulation, or (e) your violation of the rights of a third party.
            </p>
            <h6>5. Limitation of Liability.</h6>
            <p>
                TO THE EXTENT PERMITTED BY APPLICABLE LAW, ALTISOURCE, AND THEIR RESPECTIVE PARENTS, 
                SUBSIDIARIES, AND AFFILIATES, AND THEIR RESPECTIVE OFFICERS, DIRECTORS, AGENTS AND 
                EMPLOYEES ARE NOT AND SHALL NOT BE LIABLE FOR ANY DAMAGES OR LOSSES 
                (INCLUDING, BUT NOT LIMITED TO, LOSS OF MONEY, GOODWILL OR REPUTATION, PROFITS, OTHER 
                INTANGIBLE LOSSES, OR ANY SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES) ARISING DIRECTLY 
                OR INDIRECTLY FROM: (A) YOUR USE OF OR INABILITY TO USE THE Home Equity Shield WEBSITE, (B) 
                THE CONTENT ON THE Home Equity Shield WEBSITE, (C) THE AVAILABILITY OF AND NOTIFICATIONS 
                MADE BY ALTISOURCE IN CONNECTION WITH THE Home Equity Shield PROGRAM, (D) OUR PROVISION OF 
                ANY SERVICES IN CONNECTION WITH THE Home Equity Shield PROGRAM, (E) DAMAGE TO YOUR HARDWARE 
                DEVICE FROM USE OF THE Home Equity Shield WEBSITE, AND (F) ANY ACTION TAKEN WITH RESPECT TO 
                YOUR PARTICIPATION IN THE Home Equity Shield PROGRAM. IN NO EVENT SHALL ALTISOURCE, OR THEIR 
                RESPECTIVE PARENTS, SUBSIDIARIES, AND AFFILIATES AND THEIR RESPECTIVE OFFICERS, DIRECTORS, 
                AGENTS AND EMPLOYEES’ COLLECTIVE AGGREGATE LIABILITY TO YOU ARISING OUT OF OR IN CONNECTION 
                WITH THIS AGREEMENT EXCEED ONE HUNDRED DOLLARS ($100).
            </p>
            <h6>6. Legal Compliance.</h6>
            <p>
                You shall comply with all applicable local, state, federal and international laws, 
                statutes, ordinances and regulations regarding your use of the Home Equity Shield website 
                and participation in the Home Equity Shield program.
            </p>
            <h6>7. No Agency.</h6>
            <p>
                No agency, partnership, joint venture, employee-employer, franchiser-franchisee, or vendor 
                relationship is intended or created by this Agreement.
            </p>
            <h6>8. General.</h6>
            <p>
                (a) <span className="underline-txt">Severability</span>. If any provision of this Agreement is held to be invalid or 
                unenforceable, such provision shall be struck and the remaining provisions shall be enforced.
            </p>
            <p>
                (b) <span className="underline-txt">Assignment</span>. You agree that this Agreement and all referenced or incorporated 
                agreements may be automatically assigned by Altisource without your written consent to a 
                third party. You may not assign any of your rights hereunder without our prior approval.
            </p>
            <p>
                (c) <span className="underline-txt">Headings</span>. Headings are for reference purposes only and in no way define, limit, 
                construe or describe the scope or extent of such section.
            </p>
            <p>
                (d) <span className="underline-txt">No Waiver</span>. Our failure to act with respect to a breach by you or others does not 
                waive our right to act with respect to subsequent or similar breaches.
            </p>
            <p>
                (e) <span className="underline-txt">Entirety</span>. This Agreement sets forth the entire understanding and agreement 
                between us with respect to the subject matter hereof.
            </p>
            <p>
                (f) <span className="underline-txt">Fees are Nonrefundable</span>. You agree to pay all fees and costs that you incur during 
                your participation in the Home Equity Shield program and you understand that all such fees 
                and costs are non-refundable.
            </p> 
            <p>
                (g) <span className="underline-txt">Disputes</span>. This Agreement shall be governed by and construed in accordance with 
                Georgia law. Borrower agrees that any and all disputes arising from your use of the Home Equity 
                Shield website or your participation in the Home Equity Shield program shall be resolved 
                solely in the United States District Court for the Northern District of Georgia, 
                Atlanta Division.
            </p>
            <p>
                (h) <span className="underline-txt">Acceptance</span>. All persons using the Home Equity Shield website unconditionally 
                accept the terms of this Agreement.
            </p>
            <p>
                (i) <span className="underline-txt">Intellectual Property Notice</span>. Altisource, the Altisource logo, the Home Equity Shield logo 
                and all other marks identified herein, and on referenced and incorporated agreements, 
                are trademarks or service marks of Altisource or one or more of its affiliates. 
                These marks may be registered with the United States Patent and Trademark Office and 
                Intellectual Property Offices of other countries.
            </p>
            <p>
                (j) <span className="underline-txt">CLASS ACTION WAIVER</span>.  ANY CLAIMS, LAWSUITS, OR OTHER LEGAL PROCEEDINGS RELATING IN 
                ANY MANNER TO YOUR USE OF THE Home Equity Shield WEBSITE OR YOUR PARTICIPATION IN THE HOME EQUITY 
                SHIELD PROGRAM SHALL BE PURSUED AND/OR CONDUCTED ONLY ON AN INDIVIDUAL BASIS AND NOT 
                IN A CLASS OR REPRESENTATIVE ACTION, OR AS A NAMED OR UNNAMED MEMBER IN A CLASS, 
                CONSOLIDATED, REPRESENTATIVE OR PRIVATE ATTORNEY GENERAL LEGAL ACTION. YOUR ACCESS OF 
                THE Home Equity Shield WEBSITE AND PARTICIPATION IN THE Home Equity Shield PROGRAM SIGNIFIES YOUR 
                EXPLICIT CONSENT TO THIS WAIVER.
            </p>
            <h6>9. Communication Consent:</h6>
            <p>
                By submitting your contact information on the Home Equity Shield website, 
                you are providing your electronic signature and your consent to receive 
                telemarketing calls, text messages and email messages from Altisource and its affiliates, 
                including without limitation, Springhouse, LLC, Premium Title Services, Inc., and 
                Altisource Online Auction, Inc. By submitting your contact information on the Home Equity Shield 
                website, you further agree that (i) we, our employees, agents, representatives, 
                affiliates, or authorized third parties acting on our behalf may call or email you at 
                the numbers and addresses you have provided for purposes of describing goods and services 
                that may be of interest to you, offered by us and our affiliates, and/or authorized third 
                parties; and (ii) these calls, and emails may be made using an automatic telephone dialing 
                or email system technology and/or involve prerecorded and/or artificial voice messaging, 
                even if it is a cellular phone number or other service for which the called person(s) 
                could be charged for such call. Your consent, if provided, will be effective even if the 
                number you have provided is registered on any state or federal Do-Not-Call (DNC) list.
            </p>
            <p>
                This consent shall remain in effect until you revoke it. You may revoke your consent to 
                these calls or other forms of communications from us by contacting Customer Care at 
                (866) 609-4414, emailing us at <a href="mailto:info@homeequityshield.com">info@homeequityshield.com</a> or any other method that ensures we 
                receive the revocation. Your consent to telemarketing calls is not a condition of obtaining 
                any service from us under the Home Equity Shield program.
            </p>
        </div>
      </div>

      <div id="gettingStarted" className="subSection">
        <div className="wrapper">
          <div className="content">
            <h2>Getting Started Is Easy</h2>
            <p>
              Click Get Started today and let Home Equity Shield walk you through the progrm.{" "}
            </p>
          </div>
          <div className="buttonWrapper ">
            <a href="#" className="button getStarted">
              Get Started{" "}
              <img
                src={`${resUrl}/images/chevrons.png`}
                alt="get started"
                onClick={GAEventTracker(
                  "FAQ",
                  "Tapped",
                  "ES_FooterGetStartedTapped"
                )}
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
export default Terms;