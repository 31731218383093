import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import "./resources/css/fonts.css";
import "./resources/css/reset.css";
import "./resources/css/style.css";
import "./resources/css/animations.css";
import "./resources/css/media_queries.css";

import "./resources/js/main";
import "./resources/js/pulsate";
import ScrollToTop from "./ScrollToTop";
import ReactGA from "react-ga";



const TRACKING_ID_PROD = "UA-231814259-1"; // OUR_TRACKING_ID
const TRACKING_ID_QE = "UA-231814259-2"; // OUR_TRACKING_ID
const TRACKING_ID_PREVIEW = "UA-231814259-3"; // OUR_TRACKING_ID

const root = ReactDOM.createRoot(document.getElementById("root"));

ReactGA.initialize(TRACKING_ID_PROD, {
  debug: true
});

root.render(
  <BrowserRouter>
    <ScrollToTop />
      <React.StrictMode>
        <App />
      </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
