import FaqItem from "./components/FaqItem";
import { faqs } from "./resources/staticData";
import GAEventTracker from "./components/GAEventTracker";
function Faq(props) {
  const { resUrl, isShowVideo} = props;

  return (
    <>
      <div id="hero" className="faqPage getsParallax parallax">
        <div className="wrapper">
          <div className="intro">
            <div className="blueSecHeader">Resources</div>
            <div className="ctas">
              <div className="sub_headline">
                <h1>Frequently Asked Questions</h1>
              </div>
              <div className="heroButtons">
                {isShowVideo && (<div className="playButton">
                  <div className="innerButton"></div>
                  <div className="outerButton "></div>
                  <div
                    className="playArrow"
                    onClick={GAEventTracker(
                      "Header",
                      "Tapped",
                      "ES_VideoPlayerTapped"
                    )}
                  >
                    <img
                      src={`${resUrl}/images/play_arrow.png`}
                      alt="video player"
                    />
                  </div>
                </div>)}
              </div>
            </div>

            <p>
              We’re here to answer your questions. Below are a few frequently
              asked questions an answers that can help you through this process.
              However, you can reach out to our customer service center for any
              additional questions.
            </p>
          </div>
          <div className="heroImg">
            <img
              src={`${resUrl}/images/faq_hero_image.png`}
              alt="Frequently asked questions"
            />
          </div>
        </div>
      </div>

      <div id="faqSection" className="getsParallax parallax">
        <div className="wrapper">
          {faqs.map((faq) => (
            <FaqItem
              key={faq.id}
              resUrl={resUrl}
              question={faq.question}
              answer={faq.answer}
            />
          ))}
        </div>
      </div>

      <div id="gettingStarted" className="subSection">
        <div className="wrapper">
          <div className="content">
            <h2>Getting Started Is Easy</h2>
            <p>
              Click Get Started today and let Home Equity Shield walk you through the progrm.{" "}
            </p>
          </div>
          <div className="buttonWrapper ">
            <a href="#" className="button getStarted">
              Get Started{" "}
              <img
                src={`${resUrl}/images/chevrons.png`}
                alt="get started"
                onClick={GAEventTracker(
                  "FAQ",
                  "Tapped",
                  "ES_FooterGetStartedTapped"
                )}
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
export default Faq;