import {useRef} from 'react';
import { Link, useLocation } from "react-router-dom";
import GAEventTracker from './components/GAEventTracker';
import { NavHashLink } from "react-router-hash-link";

function Header(props) {

  const menuRef = useRef(null)

  const { resUrl } = props;

  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  const handleMenuClick = (action) => {
    GAEventTracker('Header','Tapped',action);
    if (menuRef && menuRef.current && menuRef.current.classList.contains("dropActive")) {
      menuRef.current.classList.remove("dropActive");
      document.querySelector('.closeBurger').style.display = "none";
    }
  }
  
  return (
    <>
      <div id="header" className="getsParallax parallax">
        <div className="wrapper">
          <div className="logo">
            <Link to="/">
              <img src={`./home_logo.png`} alt="Home Equity Shield" />
            </Link>
          </div>
          <div className="nav">
            <div className="navItem"  alt="list items" ref={menuRef}>
              <div>
                {/* <a id="howItWorksBtn" href="/#howItWorks">How it Works</a> */}
                <NavHashLink to="/#howItWorks" onClick={handleMenuClick('ES_HeaderHowItWorksTapped')} alt="How it Works" className={splitLocation[1] === "" ? "active" : ""}>How it Works</NavHashLink>
              </div>
              <div>
                <Link to="/faq" onClick={handleMenuClick('ES_HeaderResourcesTapped')} alt="Resources" className={splitLocation[1] === "faq" ? "active" : ""}>Resources</Link>
              </div>
              <div>
                <Link to="/contacts"  onClick={handleMenuClick('ES_HeaderContactUs')} alt="Contact Us" className={splitLocation[1] === "contacts" ? "active" : ""}>Contact Us</Link>
              </div>
            </div>
            <div className="hamburger">
              <img src={`${resUrl}/images/hamburger.png`} alt="menu" width="30%" />
            </div>
            <div className="closeBurger">
              <a href="#">
                <img
                  id="closeIcon"
                  className=""
                  src={`${resUrl}/svg/close.svg`}
                  type="image/svg+xml"
                  width="15%"
                  alt="close"
                  onClick={(e)=>{
                    e.preventDefault();
                  }}
                />
              </a>
            </div>
            <div className="buttonWrapper">
              <a href="#" className="button getStarted" onClick={GAEventTracker('Header','Tapped','ES_HeaderGetStartedTapped')} alt="get Started">
                Get Started
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Header;