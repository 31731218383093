import { useState, useEffect } from "react";
import GAEventTracker from './GAEventTracker';
function Calculator(props) {
  const { resUrl } = props;

  const [estimatedValue, setEstimatedValue] = useState('');
  const [oweValue, setOweValue] = useState('');
  const [commission, setCommission] = useState('');
  const [result, setResult] = useState('$?');

  const prepCalc = (value) => {
    value = value.replace('$', '');
    value = value.replace(',', '');
    value = value.replace('%', '');
    return value;
  }

  const handleChange = (e, field, min, max, limit) => {
    let changedValue = prepCalc(e.target.value);
    if (changedValue) {
      if(limit){
        changedValue = Math.max(min, Math.min(max, Number(changedValue)));
      }else{
        changedValue = Math.max(min, Number(changedValue));
      }
    }
    switch (field) {
      case "estimatedValue":
        setEstimatedValue(changedValue);
        break;
      case "oweValue":
        setOweValue(changedValue);
        break;
      case "commission":
        setCommission(changedValue);
        break;
      default:
        break;
    }
  };

  const checkAllowed = (e) => {
    const re = /[0-9.]+/i;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits:0,
    maximumFractionDigits:2
  
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  useEffect(() => {
    let result = 0;
    if (estimatedValue !='' && oweValue != '' && commission != '') {
      result = estimatedValue - (oweValue || 0) - estimatedValue * (commission || 0) * 0.01;
    //   result = result.toLocaleString("en-US");
    // } else{
    //   result = "?"
    }
    if(result){
      result = formatter.format(result);
      setResult(result);
    }
      

    return () => {
      // setResult(0);
    };
  }, [estimatedValue, oweValue, commission]);

  return (
    <>
      <div id="calculator" className="subSection2">
        <div className="wrapper">
          <div className="blueSecHeader">Estimated Equity</div>
          <div>
            <h2>Calculate Your Estimated Equity</h2>
          </div>
          <div className="calcbody">
            <div className="calcLeft">
              <div className="calcItem">
                <div className="calcImage">
                  <img src={`${resUrl}/images/value_icon.png`} alt="value icon" />
                </div>
                <div className="calcInput">
                  <span>Enter the estimated value of your home</span>
                  <input
                    type="number"
                    step="0.01"
                    className="value calcfeild"
                    value={estimatedValue}
                    onKeyPress={(e) => checkAllowed(e)}
                    onChange={(e) => {
                      handleChange(e, "estimatedValue", 0, 1000000000, true);
                    }}
                    onBlur={
                      GAEventTracker('Calculator','Enter','ES_Calc_EstimatedHomeValue')
                    }
                    aria-label="estimated Value"
                  />
                </div>
              </div>

              <div className="calcItem">
                <div className="calcImage">
                  <img src={`${resUrl}/images/owe_icon.png`} alt="owe icon" />
                </div>
                <div className="calcInput">
                  <span>Enter what you currently owe your lender</span>
                  <input
                    type="number"
                    step="0.01"
                    className="owe calcfeild"
                    value={oweValue}
                    onKeyPress={(e) => checkAllowed(e)}
                    onChange={(e) => {
                      handleChange(e, "oweValue", 0, 1000000000, true);
                    }}
                    onBlur={
                      GAEventTracker('Calculator','Enter','ES_Calc__OweLender')  
                    }
                    aria-label="owe Value"
                  />
                </div>
              </div>

              <div className="calcItem">
                <div className="calcImage">
                  <img src={`${resUrl}/images/percent_icon.png`} alt="percentage icon" />
                </div>
                <div className="calcInput">
                  <span>
                    Enter your real estate agent's estimated commission
                    percentage<sup>*#</sup>
                  </span>
                  <input
                    type="number"
                    step="0.01"
                    className="percent calcfeild"
                    value={commission}
                    onKeyPress={(e) => checkAllowed(e)}
                    onChange={(e) => {
                      handleChange(e, "commission", 0, 100, true);
                    }}
                    onBlur={
                      GAEventTracker('Calculator','Enter','ES_Calc_Commisionperc')  
                    }
                    aria-label="commission percentage"
                  />
                </div>
              </div>
            </div>

            <div className="calcRight">
              <div className="calcResult">
                <div className="title">Your home's estimated equity</div>
                <div className="result">{result}{result && result != '$?' && (<span>**</span>)}</div>
              </div>
            </div>
          </div>
          <div className="disclaimer">
            <div>
              * Not all lenders participate in this part of the program. 
              The length of the foreclosure and late fee pause, if any, will vary by lender 
              and is not guaranteed.
            </div>
            <div>
              # The performance represented is historical. Past performance is not a reliable 
              indicator of future results and no guarantees are made regarding the actual 
              sales price that can be achieved on Hubzu.  
            </div>
            <div>
              ** The estimated equity amount is not a guarantee of actual performance and does not 
              account for other costs that could reduce the amount of equity recovered from the 
              sale of your home, including without limitation, any closing costs that may apply, 
              any fees accumulated before joining the Home Equity Shield program, liens against your property, 
              the condition of your property, fluctuations in market prices, and any other 
              factors that are beyond our control. This website and the information contained 
              herein is not intended to be a source of advice or serve as a guarantee of performance.
            </div>
            <div>
              *** Broker commissions are not set by law and are negotiable. There are no standard commission rates.
            </div>
            <div id='impnotice'>
              <h4 className='noticeheader'>IMPORTANT NOTICE:</h4>
              <p className='noticedesc'>  
              Altisource is not associated with the government, and our service is not approved by the government. You may stop doing business with us at any time. You may accept or reject the services we are offering. If you reject our services, you do not have to pay us. If you accept our services, your lender/servicer will pay for the BPO and title search. You will be responsible for paying other fees and expenses associated with the sale of your home, including without limitation, brokers' commissions, closing costs, and liens against your property.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Calculator;
