import ReactGA from "react-ga";



export const initGA = trackingID => {

  ReactGA.initialize(trackingID, {
    debug: true
  });
  // ReactGA.initialize(trackingID);
};

export const PageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};


const GAEventTracker = (category, action, label) => {
  const eventTracker = () => {
    ReactGA.event({category, action, label});
  }
  return eventTracker;

}
export default GAEventTracker;