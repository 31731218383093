import $ from "jquery";

const s3ImageUrl = "https://image-prod.hubzu.com/equity";

$(document).ready(function () {
  ////// Radial Pulse play Button
  // $(".outerButton").pulsate({
  //   // color: $('.innerButton').css("background-color"), // set the color of the pulse
  //   color: "#aeaeae", // set the color of the pulse
  //   reach: 14, // how far the pulse goes in px
  //   speed: 1000, // how long one pulse takes in ms
  //   pause: 0, // how long the pause between pulses is in ms
  //   glow: false, // if the glow should be shown too
  //   repeat: true, // will repeat forever if true, if given a number will repeat for that many times
  //   onHover: false, // if true only pulsate if user hovers over the element
  // });
  // $(".outerButton").addClass("pulse");

  // $(".playButton").hover(
  //   function () {
  //     $(".playArrow").css("transform", "scale(1.2)");
  //     // $('.outerButton').removeClass('pulse');
  //   },
  //   function () {
  //     $(".playArrow").css("transform", "scale(1)");
  //     $(".outerButton").addClass("pulse");
  //   }
  // );

  // $(".faq .title").on("click", function () {
  $( 'body' ).on( 'click', '.faq .title', function (e) {
    if ($(this).siblings(".answer").is(":visible")) {
      $(".faqDrop img", this).attr("src", `${s3ImageUrl}/images/faq_min.png`);
    } else {
      $(".faqDrop img", this).attr("src", `${s3ImageUrl}/images/faq_plus.png`);
    }
    // $(this).siblings(".answer").slideToggle();
  });

  checkParallax();
  $(window).resize(function () {
    checkParallax();
  });

  function checkParallax() {
    var windowWidth = $(window).width();
    console.log(windowWidth);
    if (windowWidth > 1000) {
      $(".getsParallax").addClass("parallax");
    } else {
      $(".getsParallax").removeClass("parallax");
    }
  }

  // $(".hamburger").on("click", function () {
  $( 'body' ).on( 'click', '.hamburger', function (e) {
    $(".navItem").addClass("dropActive");
    setTimeout(function () {
      $(".closeBurger").show();
    }, 300);
  });

  // $(".closeBurger").on("click", function () {
  $( 'body' ).on( 'click', '.closeBurger', function (e) {
    $(".navItem").removeClass("dropActive");
    $(".closeBurger").hide();
  });

  // $(".playButton").on("click", function () {
  $( 'body' ).on( 'click', '.playButton', function (e) {
    $(".overlay").show();
    $(".video").css("display", "flex");
    $(".closeOverlay").show();
  });

  // $(".getStarted").on("click", function (e) {
  //   e.preventDefault();
  //   $(".overlay").show();
  //   $(".formBlock").css("display", "flex");
  //   $(".formWrapper").css("display", "flex");
  //   $(".closeOverlay").show();
  //   return false;
  // });

  $( 'body' ).on( 'click', '.getStarted', function (e) {
    e.preventDefault();
    $(".overlay").show();
    $(".formBlock").css("display", "flex");
    $(".formWrapper").css("display", "flex");
    $(".closeOverlay").show();
    return false;
  });


  // $(".closeOverlay").on("click", function () {
  $( 'body' ).on( 'click', '.closeOverlay, .closeModalBox, .terms-link', function (e) {
    $(".overlay").hide();
    $(".video").css("display", "none");
    $(".closeOverlay").hide();
    $(".formBlock").css("display", "none");
    $(".formWrapper").css("display", "none");
  });

  // $(".calcfeild").on("input", function () {
  //   var owe = prepCalc($(".owe").val());
  //   console.log(owe);
  //   var value = prepCalc($(".value").val());
  //   var percent = prepCalc($(".percent").val());
  //   if (owe != "" && value != "" && percent != "") {
  //     var result = runCalc(owe, value, percent);
  //     console.log(result);
  //     result = "$" + result.toLocaleString("en-US") + "<span>**</span>";
  //     $(".result").html(result);
  //   }
  // });

  // $("#howItWorksBtn").on("click", function (e) {
  $( 'body' ).on( 'click', '#howItWorksBtn', function (e) {
    e.preventDefault();
    var targetId = document.getElementById("howItWorks");
    if(targetId){
      // targetId.scrollIntoView({ behavior: "smooth", block: "start" });
      $('html, body').animate({
          scrollTop: $("#howItWorks").offset().top - 50
      }, 1500);

    } else {
      window.location.href = "/#howItWorks";
    }
  });

  // $(".animated-svg").each(function () {
  //   var svg = $(this);
  //   var distance = getOffSetDistance($(this).offset().top);
  //   $(window).scroll(function () {
  //     // Get the Object by ID
  //     // console.log(svg.attr('id'));
  //     var id = svg.attr("id");
  //     animateStep(id, distance);
  //     console.log('Welcome to scroll animation');
  //   });
  // });

  // $(".benefit").each(function () {
  //   var svg = $(this);
  //   var distance = getOffSetDistance($(this).offset().top);

  //   $(window).scroll(function () {
  //     if ($(window).scrollTop() >= distance) {
  //       // console.log(svg.width());
  //       svg.addClass("active");
  //     }
  //   });
  // });

  $("#hero").each(function () {
    var svg = $(this);
    var distance = $("#hero").height() - 50;
    console.log(distance);
    $(window).scroll(function () {
      if ($(window).scrollTop() >= distance) {
        // console.log($(window).scrollTop());
        $("#header").addClass("colored");
      } else {
        $("#header").removeClass("colored");
      }
    });
  });
  document.addEventListener(
      'scroll',
      (event) => {
        console.log('Welcome to scroll');
        $(".animated-svg").each(function () {
          var svg = $(this);
          var distance = getOffSetDistance($(this).offset().top);
          var id = svg.attr("id");
          animateStep(id, distance);
        });
      }
  );

});

function runCalc(owe, value, percent) {
  console.log(owe);
  var calc = value - owe - (percent / 100) * value;
  return calc;
}

function prepCalc(value) {
  value = value.replace("$", "");
  value = value.replace(",", "");
  value = value.replace("%", "");
  return value;
}

// window.onload = function () {
  
// };

function getOffSetDistance(distance, offset = 100) {
  var scroll = $(window).scrollTop();
  var window_height = $(window).height() - offset;
  var subtract = window_height;
  return distance - subtract;
}

function animateStep(id, distance) {
  var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
  if ($(window).scrollTop() >= distance) {
    var a = document.getElementById(id);
    // Get the SVG document inside the Object tag
    var svgDoc = a.contentDocument;
    // Get one of the SVG items by ID;
    var svgItem = svgDoc.getElementById("doc");
    svgItem.setAttribute("class", "active");
    var svgItem = svgDoc.getElementById("number");
    svgItem.setAttribute("class", "active");
    var svgItem = svgDoc.getElementById("background");
    svgItem.setAttribute("class", "active");
    // $(this).addClass("scroll-active");
    // $(this).find('object').contents().find('svg .animated-inner').addClass("scroll-active");
  }
}