import $ from 'jquery';
import { useEffect } from 'react';
import Calculator from "./components/Calculator";
import { Link } from "react-router-dom";
import GAEventTracker from './components/GAEventTracker';
import step1 from "./resources/svg/step1.svg";
import step2 from "./resources/svg/step2.svg";
import step3 from "./resources/svg/step3.svg";
import step4 from "./resources/svg/step4.svg";
import ReactGA from 'react-ga';


function Home(props) {
  const { resUrl, contactUs, isShowVideo } = props;



  useEffect(() => {

    function getOffSetDistance(distance, offset = 100) {
      var scroll = $(window).scrollTop();
      var window_height = $(window).height() - offset;
      var subtract = window_height;
      return distance - subtract;
    }
    $(".benefit").each(function () {
      var svg = $(this);
      var distance = getOffSetDistance($(this).offset().top);
  
      $(window).scroll(function () {
        if ($(window).scrollTop() >= distance) {
          // console.log(svg.width());
          svg.addClass("active");
        }
      });
    });
    ReactGA.pageview("Home Page Loaded");

    return () => {
      
    }

  }, []);

 

  const handleRediretion = () => {
    GAEventTracker('Protect your Equity','Tapped','ES_ProtectYourEquityResourcesTapped');
  }
  
  return (
    <>
      <div id="hero" className="getsParallax parallax">
        <div className="wrapper">
          <div className="intro">
            <h1>
              {/* Stop the Foreclosure Process and Protect Your Hard-Earned Equity */}
              Facing Foreclosure? Worried About Protecting Your Equity? We Can Help!
            </h1>
            <p>
              <b>You are not alone.</b> We understand the impact that a foreclosure can have on you 
              and your family. Your lender has referred you to this unique program to help you 
              protect the equity you've built up in your home. Continue reading to learn more or 
              click below to get started.&nbsp;
            </p>
            <div className="ctas">
              <div className="getStarted"></div>
              <div className="md-screen heroButtons">
                <div className="buttonWrapper">
                  <a href="#"  onClick={GAEventTracker('Header','Tapped','ES_HeaderGetStartedTapped')} className="button getStarted" >
                    Get Started 
                  </a>
                </div>
                {isShowVideo && (<div className="playButton">
                  <div className="innerButton"></div>
                  <div className="outerButton "></div>
                  <div className="playArrow" onClick={GAEventTracker('Header','Tapped','ES_VideoPlayerTapped')}>
                    <img src={`${resUrl}/images/play_arrow.png`} alt="videp player" />
                  </div>
                </div>)}
              </div> 
            </div>
          </div>
          <div className="video-container">
            <div className="video-wrapper">
              <iframe className="equity-video-player"
                src="https://www.youtube.com/embed/kCRcnccneqY?rel=0&showinfo=0" 
                title="YouTube video player" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen>
              </iframe>
            </div>
          </div>
          {/* <div className="heroImg">
            <img src={`${resUrl}/images/home_header_people.png`} alt="people" />
          </div> */}
        </div>
        <div className="lg-screen heroButtons">
          <div className="buttonWrapper">
            <a href="#"  onClick={GAEventTracker('Header','Tapped','ES_HeaderGetStartedTapped')} className="button getStarted" >
              Get Started 
            </a>
          </div>
        </div>
      </div>
      <div id="protectYourEquity" className="getsParallax">
        <div className="wrapper">
          <div className="blueSecHeader">Know Your Options</div>
          <div className="">
            <h2>Protect Your Equity</h2>
          </div>
          <div className="protectContent">
            <p>
              Your lender has chosen Home Equity Shield as an option to help you avoid foreclosure and protect the equity in your home. 
              As the government’s mortgage forbearance programs come to an end, homeowners like you are faced with the uncertainty that comes with a foreclosure. 
              Many homeowners are considering the option to sell their homes to avoid the long-term damage to their credit caused by a foreclosure. 
              A foreclosure also makes it much more difficult to own a home again when you get back on your feet. 
              In addition, the fees associated with late payments and the foreclosure process can quickly erode your home’s equity. 
            </p>

            <p>
              <b>
              The Home Equity Shield program can help you pause foreclosure proceedings and late fees for up to 120 days. 
              This pause begins once you and the lender execute a signed agreement. It will remain in place for the length of the program 
              as long as you provide proof that you are actively marketing your home for sale. 
              For more details, see the How it Works section below or visit <p><b> our <a href="/faq" className='resources' onClick={handleRediretion}>Resources</a> page.</b></p>
              </b>
            </p>

            <p>
              To learn more about this program and how it can potentially help you retain more of your 
              home equity, click the chat icon below or call us at&nbsp;<b>{contactUs}</b>. 
              To get started today, click Get Started. You'll need the unique ID assigned to you by your lender.
            </p>
          </div>
          <div className="buttonWrapper">
            <a href="#" className="button getStarted"  onClick={GAEventTracker('Header','Tapped','ES_HeaderGetStartedTapped')}>
              Get Started
            </a>
          </div>
        </div>
      </div>

      <div id="programBenefits">
        <div className="wrapper">
          <div className="programHeader">
            <h2>Program Benefits</h2>
          </div>
        </div>
        <div className="colWrapper">
          <div className="setOne set">
            <div className="benefit">
              <div className="icon">
                <img src={`${resUrl}/images/time_icon.png`} alt="time icon" />
              </div>
              <div className="content">
                <h3>Time</h3>
                Can potentially pause the foreclosure process and associated fees for 120 days*
              </div>
            </div>

            <div className="benefit">
              <div className="icon">
                <img src={`${resUrl}/images/valuations_icon.png`} alt="valuation icon" />
              </div>
              <div className="content">
                <h3>Valuation</h3>
                Helps you understand the value of your home in the current real estate market
              </div>
            </div>

            <div className="benefit">
              <div className="icon">
                <img src={`${resUrl}/images/options_icon.png`} alt="options icon" />
              </div>
              <div className="content">
                <h3>Options</h3>
                Gives you multiple cost-effective options to sell your home and maximize the equity that goes back into your pocket &nbsp;
              </div>
            </div>
          </div>

          <div className="centerIcon">
            <img src={`${resUrl}/images/main_program_icon.png`} alt="Home Equity Shield how it works center icon" />
          </div>

          <div className="setTwo set">
            <div className="benefit">
              <div className="icon">
                <img src={`${resUrl}/images/leaseback_icon.png`} alt="leaseback icon" />
              </div>
              <div className="content">
                <h3>Leaseback Program</h3>
                Work with your agent and the buyer to lease the property after the sale. 
                This allows you to plan for what's next without having to leave your property
              </div>
            </div>

            <div className="benefit">
              <div className="icon">
                <img src={`${resUrl}/images/minimize_icon.png`} alt="minimize icon" />
              </div>
              <div className="content">
                <h3>Minimize Damage to Credit</h3>
                A foreclosure on your credit report can harm your ability to own another 
                home for many years. Home Equity Shield can help you reach a larger buyer network 
                and help you sell without the stigma associated with a foreclosure &nbsp;
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="gettingStarted" className="subSection">
        <div className="wrapper">
          <div className="content">
            <h2>Getting started is easy</h2>
            <p>
              Click Get Started today and let Home Equity Shield walk you through the program.
            </p>
          </div>
          <div className="buttonWrapper ">
            <a href="#" className="button getStarted" onClick={GAEventTracker('Header','Tapped','ES_FooterGetStartedTapped')}>
              Get Started <img src={`${resUrl}/images/chevrons.png`} alt="get started" />
            </a>
          </div>
        </div>
      </div>

      <div id="howItWorks" className="subSection">
        <div className="wrapper">
          <div className="blueSecHeader">How It Works</div>
          <div className="title">
            <h2>How to Protect Your Equity in 4 Simple Steps</h2>
          </div>

          <div className="step">
            <div className="stepImg animated-img">
              {/* <img src={`${resUrl}/images/step_1_icon.jpg`} alt="step 1" /> */}
              <object id="svgObject1" className="animated-svg" data={step1} type="image/svg+xml" width="100%">
                <img src={`${resUrl}/images/step_1_icon.jpg`}  alt=""/>
              </object>
            </div>
            <div className="stepCopy">
              <h2>
                <span className="teal">Step 1</span>: Receive your unique ID and sign up
              </h2>
              <p>
                Your lender provided you with a unique ID which allows you to sign up for the 
                Home Equity Shield program. Just click the Get Started button to provide your 
                contact information and unique code. Please note that filling out the form does not 
                obligate you to sell your property.
              </p>

              <p>
                Once we receive your information, an Home Equity Shield representative will reach out to 
                you to discuss the program and provide you with an agreement. We'll also notify your 
                lender that you have decided to participate in the Home Equity Shield program. 
                If your lender agrees to pausing the forclosure process and associated fees, 
                and a signed agreement is in place, participating lenders may pause the foreclosure 
                process and suspend late fees for up to 120 days.* This pause will remain in effect 
                as long as you provide proof that you are actively marketing your property for sale.
              </p>
              <div className="buttonWrapper">
                <a href="#" className="button getStarted"  onClick={GAEventTracker('Header','Tapped','ES_HowItWorksGetStartedTapped')}>
                  Get Started
                </a>
              </div>
            </div>
          </div>

          <div className="step reverse">
            <div className="stepCopy">
              <h2>
                <span className="teal">Step 2:</span> Schedule your <b>free</b> home valuation
              </h2>
              <p>
                Once you’ve signed up for the program, your Home Equity Shield customer specialist will work with you throughout the process. 
                Depending on our lender, your specialist will order a&nbsp;<b> free Automated Valuation Model (AVM)</b>, or coordinate with you 
                to set a day and time for a local broker, or real estate agent, to discreetly perform 
                a&nbsp;<b>free home valuation, also known as a Broker Price Opinion (BPO).</b>&nbsp;
              </p>

              <p className="drop">
                <b>What is an AVM?</b> What is an AVM? An AVM is a software-based tool that helps estimate the value of a residential property. 
                AVMs can estimate the value of a property by using real local market data, such as county assessor information, 
                historical property sales, current real estate listings, property comparables and much more. 
              </p>

              <p className="drop">
                <b>What is a BPO?</b> A BPO is performed by a licensed real estate professional 
                by visiting your home to determine the condition and value of the property. 
                The real estate agent will take into consideration several factors like property 
                condition, age of the home, size of the home and property, property location and 
                comparable properties, among other factors. After analyzing these factors, 
                you will receive your home valuation, so you have a better idea of what your home is 
                worth in the current real estate market.
              </p>
            </div>
            <div className="stepImg animated-img">
              {/* <img src={`${resUrl}/images/step_2_icon.jpg`} alt="step 2" /> */}
              <object id="svgObject2" className="animated-svg" data={step2} type="image/svg+xml" width="100%">
                <img src={`${resUrl}/images/step_2_icon.jpg`} alt="step 2"/>
              </object>
            </div>
          </div>

          <div className="step">
            <div className="stepImg animated-img">
              {/* <img src={`${resUrl}/images/step_3_icon.jpg`} alt="Step 3" /> */}
              <object id="svgObject3" className="animated-svg" data={step3} type="image/svg+xml" width="100%">
                <img src={`${resUrl}/images/step_3_icon.jpg`} alt="step 3"/>
              </object>
            </div>
            <div className="stepCopy">
              <h2>
                <span className="teal">Step 3</span>: Review your home valuation
              </h2>
              <p>
                When your custom home valuation is completed, your Home Equity Shield specialist will 
                review the results and walk you through your property's estimated market value. 
                Understanding your detailed home valuation and available options will help you make 
                the best decision for you and your family.
              </p>
            </div>
          </div>

          <div className="step reverse">
            <div className="stepCopy">
              <h2>
                <span className="teal">Step 4</span>: Take action!
              </h2>
              <p>
                Now it's time to decide what's best for you and your family. Participating in the 
                Home Equity Shield program gives you several options to sell your property. 
                You decide what works best for you. You are not obligated to select 
                any of the options below and you can withdraw from the Home Equity Shield program at any time.  
              </p>

              <p className="drop">
                <b>Stay in Your Home:</b> Home Equity Shield can help you negotiate a leaseback 
                agreement allowing you to stay in your home for the period of time you specify 
                after the sale with a pre-negotiated rent payment. This option gives you and your 
                family the time needed to determine your next move without having to deal with 
                the foreclosure process and scrambling to find other accommodations. 
                The additional time, and having your equity in hand, can give you peace of 
                mind and much-needed flexibility.&nbsp;
              </p>

              <p className="drop">
                <b>Signature Seller:</b> Home Equity Shield gives you the ability to participate in Hubzu's 
                Signature Seller program. Hubzu is a national home auction marketing platform 
                where you can market your property in an online auction. This option can help you 
                create more competition for your property. Plus, around 60% of sellers who 
                market their properties on Hubzu sell them for $19,000 more than the list price.
                <sup>#</sup> Hubzu's 2+ million registered users are active on the platform and are 
                looking for properties to invest in. The leaseback option is available through the 
                Signature Seller program as well. A listing agent will be required to participate in 
                the Signature Seller program. Agent commissions are traditionally around 6%*** of the 
                final sale price of your home; however, you may be able to save on the commission 
                by using a participating RHSS agent. To learn more about the Signature Seller program,&nbsp;
                <a href="https://www.hubzu.com/sell" target="_blank">
                  click here.
                </a>
              </p>

              <p className="drop">
                <b>Traditional Sale:</b> You have the option to list your property for sale with a 
                real estate agent of your choice. Your agent will list your property on the MLS 
                and help you with showings, negotiating offers, contracts, etc. 
                Please keep in mind that your agent will be entitled to a commission on the sale of 
                your property. It is your responsibility to negotiate the broker commission directly 
                with your agent. Agent commissions are traditionally around 6%*** of the final sale price of your home. 
                For example, on a $300,000 final sale price, the commission could be around $18,000. 
                Once you have a buyer for your property, you'll need to let your Home Equity Shield 
                specialist and lender know. Remember, your foreclosure proceedings and late fees are only paused
                once you have a Home Equity Shield agreement in place with your lender and you begin to actively 
                market your property for sale.
              </p>
            </div>
            <div className="stepImg animated-img">
              {/* <img src={`${resUrl}/images/step_4_icon.jpg`} alt="step 4" /> */}
              <object id="svgObject4" className="animated-svg" data={step4} type="image/svg+xml" width="100%">
                <img src={`${resUrl}/images/step_4_icon.jpg`} alt="step 4"/>
              </object>
            </div>
          </div>
        </div>
      </div>

      <Calculator resUrl={resUrl} />
      <div id="timeToStart" className="subSection">
        <div className="wrapper">
          <div className="content">
            <h2>Need more information?</h2>
            <p>
              <b>If you have questions about the program, please contact us at
              866.609.4414 or chat with a representative today using the chat
              icon below. We are ready to answer your questions.</b>
            </p>
          </div>
          <div id="contactus" className="buttonWrapper ">
            {/* <a href="" className="button black">
              Contact Us <img src={`${resUrl}/images/chevrons.png`} alt="" />
            </a> */}
            <Link to="/contacts" className="button black">
              Contact Us <img src={`${resUrl}/images/chevrons.png`} alt="get started" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
export default Home;
